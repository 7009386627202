import React from "react";
import { useEffect } from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import moment from "moment/moment";
import { websiteAnalytics } from "../api/analytics";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useBetween } from "use-between";
import useShareableState from "../Common/useShareableState";
import { getModules } from "../api/auth";

Chart.register(...registerables);

const websiteCardData = [
    {
        id: 0,
        name: "Total views/ events",
        color: "orange",
        count: 38576,
        description: "Total views till date",
    },
    {
        id: 1,
        name: "Total users",
        color: "lightseagreen",
        count: 4500,
        description: "Total views till date",
    },
    {
        id: 2,
        name: "Pages per user",
        color: "#E92E26",
        count: 21473,
        description: "Total views till date",
    },
    {
        id: 3,
        name: "Avg. Time spent",
        color: "royalblue",
        count: 4,
        description: "Total time spent",
    },
    {
        id: 4,
        name: "New Users",
        color: "purple",
        count: 21478,
        description: "Total new users"
    },
    {
        id: 5,
        name: "Returning Users",
        color: "green",
        count: 21478,
        description: "Total returning users"
    },
];

const CountStyles = styled.div`
  color: ${(props) => props.color};
  font-size: 24px;
  font-weight: 600;
`;

const AnalyticsCard = styled.div`
  background: #ffffff;
  padding: 6px 10px 10px 20px;
  min-width: 25%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  border-radius: 5px;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    left: 0px;
    top: 20%;
    height: 65%;
    border-radius: 20px;
    margin-left: -3px;
    border-left: 8px solid ${(props) => props.color};
  }
`;

function WebsiteAnalyticsPage() {

    const [websiteAnalyticsData, setWebsiteAnalyticsData] = useState();
    const [websiteData, setWebsiteData] = useState(websiteCardData);
    const [loading, setLoading] = useState(true);
    const { setModulesList } = useBetween(useShareableState);

    const currentDate = new Date();
    const yyyy = currentDate.getFullYear();
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const dd = String(currentDate.getDate()).padStart(2, '0');
    let todayFormatted = `${yyyy}-${mm}-${dd}`;
    let firstDayOfMonth = `${yyyy}-${mm}-01`;

    if (dd === '01') {
        // If current date is the first day of the month
        const lastMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0); // Last day of last month
        const lastMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1); // First day of last month
        const lastMonthStartFormatted = `${lastMonthStart.getFullYear()}-${String(lastMonthStart.getMonth() + 1).padStart(2, '0')}-${String(lastMonthStart.getDate()).padStart(2, '0')}`;
        const lastMonthEndFormatted = `${lastMonthEnd.getFullYear()}-${String(lastMonthEnd.getMonth() + 1).padStart(2, '0')}-${String(lastMonthEnd.getDate()).padStart(2, '0')}`;
        
        firstDayOfMonth = lastMonthStartFormatted;
        todayFormatted = lastMonthEndFormatted;
    } else {
        // If current date is not the first day of the month
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1); // Get yesterday's date
        const yesterdayFormatted = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;
        
        todayFormatted = yesterdayFormatted;
    }

    const [dateFrom, setDateFrom] = useState(firstDayOfMonth);
    const [dateTo, setDateTo] = useState(todayFormatted);


    useEffect(() => {
        document.title = "Website Analytics - Clarion Audio";
    });

    const getModuleDetails = async () => {
        let res = await getModules();
        if (res?.status) {
            setModulesList(res?.payload);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getModuleDetails();
        }
    }, [])

    const options = {
        responsive: true,
        aspectRatio: 3,
        backgroundColor: "green",
        plugins: {
            legend: {
                position: "top",
                labels: {
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };
    const fetchAnalyticsData = async (getUrl) => {
        let websiteAnalyticsRes = await websiteAnalytics(getUrl);
        if (websiteAnalyticsRes?.status === 1) {
            setLoading(false);
            setWebsiteAnalyticsData(websiteAnalyticsRes.payload);
        } else {
            setLoading(false);
            setWebsiteAnalyticsData();
        }
    };

    const handleApply = async () => {
        if (!dateFrom || !dateTo) {
            alert('Please select date range');
            return;
        }
        // Convert dates to Unix timestamps at the correct times
        const fromDate = new Date(dateFrom);
        fromDate.setHours(0, 0, 0, 0);
        const fromTimestamp = Math.floor(fromDate.getTime() / 1000);

        const toDate = new Date(dateTo);
        toDate.setHours(23, 59, 59, 999);
        const toTimestamp = Math.floor(toDate.getTime() / 1000);

        if (fromTimestamp > toTimestamp) {
            alert('Please select valid date range');
            return;
        }
        const apiUrl = `/analytics/website/?from=${fromTimestamp}&to=${toTimestamp}`;

        setLoading(true);
        await fetchAnalyticsData(apiUrl);
    };

    const getAnalyticsData = async () => {

        await fetchAnalyticsData('/analytics/website');
    };
    useEffect(() => {
        getAnalyticsData();
    }, []);



    useEffect(() => {
        const webData = websiteData.map((object) => {
            return {
                ...object,
                dataCount:
                    object.id === 0
                        ? websiteAnalyticsData?.totalEvents
                        : object.id === 1
                            ? websiteAnalyticsData?.totalUsers
                            : object.id === 2
                                ? websiteAnalyticsData?.pagesPerUser
                            : object.id === 3
                                ? websiteAnalyticsData?.avgTimeSpent
                            : object.id === 4
                                ? websiteAnalyticsData?.newUsers
                                : websiteAnalyticsData?.returningUsers
            };
        });

        setWebsiteData(webData);
    }, [websiteAnalyticsData]);

    var labels = [];
    var today = new Date();
    for (var i = 0; i < 16; i++) {
        var date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - i * 2
        );
        labels.push(moment(date).format("MMM DD YYYY"));
    }
    labels.push(0);

    const websiteGraphData = {
        labels: [
            0,
            websiteAnalyticsData?.viewsData?.map((item) => item.date),
        ].flat(),
        datasets: [
            {
                label: "Total views",
                data: [
                    0,
                    websiteAnalyticsData?.viewsData?.map((item) => Number(item.value)),
                ].flat(),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };



    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Website Analytics</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">Home</li>
                                                <li className="breadcrumb-item">Analytics</li>
                                                <li className="breadcrumb-item active">Website Analytics</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {loading ? (
                            <div className="page-loading">
                                <ReactLoading
                                    type={"spin"}
                                    color={"#E92E26"}
                                    height={"50px"}
                                    width={"50px"}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="pb-5 pt-3 px-4">
                                    <div className="row g-3">
                                        <div className="col-lg-5"></div>
                                        <div className="col-lg-7">
                                            <div className="row g-2 justify-content-end">
                                                <div className="col-5">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">From</span>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="dateFrom"
                                                            aria-label="dateFrom"
                                                            min='2023-01-01'
                                                            max={new Date().toISOString().split('T')[0]}
                                                            value={dateFrom}
                                                            onChange={(e) => setDateFrom(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon2">To</span>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="dateTo"
                                                            aria-label="dateTo"
                                                            min='2023-01-01'
                                                            max={new Date().toISOString().split('T')[0]}
                                                            value={dateTo}
                                                            onChange={(e) => setDateTo(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="input-group mb-3">
                                                        <button className="btn btn-outline-primary w-100" onClick={handleApply}>Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analytics-website row g-3">
                                        {websiteData.map((card) => {
                                            return (
                                                <>
                                                    <div className="col-md-6 col-xl-4">
                                                        <AnalyticsCard
                                                            key={card.id}
                                                            // className="analytics-clarion-audio"
                                                            color={card.color}
                                                        >
                                                            <CountStyles color={card.color}>
                                                                {card.dataCount}{" "}
                                                            </CountStyles>
                                                            <div className="analytics-type">
                                                                {card.name.toUpperCase()}
                                                            </div>
                                                            <p>{card.description}</p>
                                                        </AnalyticsCard>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>
                                    <Line options={options} data={websiteGraphData} />
                                </div>

                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default WebsiteAnalyticsPage;
