import React from 'react';
import { useEffect } from "react";
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';

function Dashboard() {
    useEffect(() => {
        document.title = "Dashboard - Clarion Audio"
    },);
    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">                            
                            <div className="row text-center mt-5">
                                <div className="col-md-12">
                                    <img src="/assets/images/dashboard.png" alt="Dashboard" className="img-fluid w-25"/>
                                    <h2 className='mt-5'>Welcome to Clarion Audio</h2>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer /> 
        </div>
    )
}

export default Dashboard;