import Swal from "sweetalert2";
import { get, putAPI } from "../services/api.services";
const { defaultPage } = require("../Common/Constant");

export const dealerList = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  status,
  searchKeyword = "",
}) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    status,
    q: searchKeyword,
  };
  let res = await get("/dealer/list", data);
  return res;
};

export const dealerUpdateStatus = async ({ id, status }) => {
  return Swal.fire({
    text: `Are you sure you want to ${
      status === "authorized" ? "Approve" : "Cancel"
    }?`,
    confirmButtonText: "Yes",
    showCancelButton: true,
  }).then(async (result) => {
    if (result.value) {
      let res = await putAPI("/dealer/" + id + "/update-status/", {
        status: status,
        remarks: "",
      });
      return Swal.fire({
        text: res.message,
        timer: 1500,
      }).then(() => {
        return res;
      });
    }
  });
};
