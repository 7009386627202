import { useState } from "react";
import { Modal } from "react-bootstrap";

function EditModal(props) {
  const [show, setShow] = useState(false);
  const [moduleName, setModuleName] = useState(props?.moduleName);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              handleClose();
              e.preventDefault();
              props?.updateModule(props?.id, moduleName);
            }}
            id="editmodal"
            className="w-full max-w-sm"
          >
            <div className="md:flex md:items-center mb-6">
              <div className="md:w-1/3">
                <label
                  className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading"
                  for="name"
                >
                  Module Name
                </label>
              </div>
              <div className="md:w-2/3">
                <input
                  className="bg-gray-200 input-styles border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                  id="name"
                  placeholder="Analytics"
                  type="text"
                  value={moduleName}
                  onChange={(e) => {
                    setModuleName(e.target.value);
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-add-button"
            onClick={handleClose}
            form="editmodal"
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <i className="ri-edit-2-line pointerC" onClick={handleShow} />
    </>
  );
}
export default EditModal;
