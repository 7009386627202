import React, { useState, useEffect, useRef } from 'react';
import { defaultPage } from '../../Common/Constant';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate } from "react-router-dom";
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

import * as Category from '../../api/category';
import * as Menu from '../../api/menu';
import * as Page from '../../api/page';
import * as Blog from '../../api/blog';
import * as Product from '../../api/product';

function MenuList() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Menu List - Clarion Audio";
    },);
    const [parentMenuID, setParentMenuID] = useState();
    const [parentMenu, setParentMenu] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [menuInfo, setMenu] = useState({});//menu  form modal display control & single menu details
    const [menuItem, setMenuItem] = useState({});//single menu item form control
    const [itemLists, setItemList] = useState([]); // menu item list under a menu
    const [refObjects, setRefObjects] = useState([]);
    
    const dragItem = useRef();
    const dragOverItem = useRef();

    const dragStart = (e, position) => {
        dragItem.current = position;
    };
    
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };
     
    const drop = (e) => {
         const dragItemContent = itemLists[dragItem.current];
        itemLists.splice(dragItem.current, 1);
        itemLists.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setItemList([...itemLists]);
        resetPrirority();
    };

    const resetPrirority=async()=>{
        let priorities = []
        itemLists.map(item=>{
            priorities.push(item.ID);
        })
        Menu.updateItemPriority(priorities);
    }

       
    const getList = async() =>{
            let res = await Menu.getList({page:0, recPerPage: 50, searchKeyword: searchKeyword, parentID: parentMenuID});
            if(res.status === 1){
                setLists(res.payload)
                setPageInfo(res.paginationInfo)
            } else{
                setLists([])
                document.getElementById("noRecordMsg").innerHTML = "No menu found";
            }        
    }
    useEffect(() => {
        getList();
    },[]);

    const getMenuType = async(menuType) =>{       
        document.getElementById('menuLink').value = "";        
        document.getElementById('menuText').value = "";   
        document.getElementById('menuLink').disabled = false;

        let objects = []; let res1 = {};
        switch(menuType) {
            case "1" : 
                res1 = await Category.categoryList({});
                break;
            case "4" : 

                res1 = await Product.productList({});                
                break;
            case "5" : 
                res1 = await Page.getList({});
                break;
            case "6" : 
                res1 = await Blog.getList({});
                break;
        }

        let options = '<option value="">Select an option</option>';
        if(res1.status && res1.status === 1){
            objects = res1.payload;
            objects.map(async(object)=>{
                options +=`<option value=${object.ID}>${object.title}</option>`;
            })           
        };        
        document.getElementById('objectId').innerHTML = options; 
        setRefObjects(objects);

        
    }

   const setMenuTextAndLink=(e)=>{
        let sel = e.target;
       
        menuItem['objectId'] = sel.value;
        menuItem['menuText'] = sel.options[sel.selectedIndex].text;

        let slug = "";
        refObjects.map(async(object)=>{
            if(object.ID == sel.value){
                slug = object.slug;
            }
        })  
        let link = "";
        switch(menuItem['menuType']) {
            case "1" :      
                link = 'collections/'+slug;       
                menuItem['menuLink'] = link;
                break;
            case "4" :  
                link = 'products/'+slug;
                menuItem['menuLink'] = link;
                break;
            case "5" : 
                link = 'pages/'+slug;
                menuItem['menuLink'] = link;
                break;
            case "6" : 
                link = 'blog/'+slug;
                menuItem['menuLink'] = 'blogs/'+slug;
                break;
        }
        document.getElementById('menuLink').value = link;
        document.getElementById('menuText').value = sel.options[sel.selectedIndex].text; 
        document.getElementById('menuLink').disabled = false;
        if(link != ""){
            document.getElementById('menuLink').disabled = true;
        }
   }

   
    

    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await Menu.updateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    const deleteRow = async (id) => {
        let res = await Menu.trash({id:id})
        if(res && res.status === 1){
            getList()
        }
    }

    const changePage = (pageInfo) => {
        document.getElementById('menuLink').value = "";        
        document.getElementById('menuText').value = "";   
        document.getElementById('menuLink').disabled = false;
        setPageInfo(pageInfo);
    }
    
    const submitMenuItem = async(e) => {
        e.preventDefault();
        setErrors();
        menuItem.menuID = menuInfo?.detail?.ID;
        console.log(menuItem)
        //const formErrors = validateForm();
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // } else {
           
            let res = {};
            if(menuItem.ID && menuItem.ID != null && menuItem.ID!= '' && menuItem.ID != 0){
                res = await Menu.updateItem({payload:menuItem});
            }else{
                res = await Menu.createItem({payload:menuItem});
            }
            if(res.status === 1){
            loadMenuItems(menuItem.menuID);
            resetItemForm({})
               // navigate("/web/menu");
            }//  else {
            //     setErrors({...errors,['submit']:res.message})
            // }
        //}
    }

    const loadMenuItems = async(ID)=>{
        let res = await Menu.getItemList({page:0, recPerPage: 50, searchKeyword: searchKeyword, menuID: ID});
        if(res.status === 1){
            setItemList(res.payload)
            // setPageInfo(res.paginationInfo)
        } else{
            setItemList([])
            //document.getElementById("noRecordMsg").innerHTML = "No menu found";
        } 
    }

    const resetItemForm = async(item) =>{
        setMenuItem(item);
    }

    const updateItemStatus = async(menuID,ID,status) =>{
        status = status === 0 ? 1 : 0;
        let res = await Menu.updateItemStatus({id:ID,status:status});
        if(res && res.status === 1){
            loadMenuItems(menuID);
            //e.target.checked = status;          
        }
    }

    const deleteItem = async(menuID,ID) =>{
        let res = await Menu.trashItem({id:ID})
        if(res && res.status === 1){
            loadMenuItems(menuID);
        }
    }

    

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Menu List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Menu</li>
                                                <li className="breadcrumb-item active">Menu List</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 ">
                                    <div className="col-2" style={{marginLeft: "10px", textAlign: "right"}}>
                                        <div className="form-group">
                                            <button className="btn btn-primary" onClick={() => navigate('/web/menu/add')}> <i className="ri-add-line"></i> Add Menu</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-0">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Icon</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Name</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Priority</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Items</th> 
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr >
                                                                <td>{i+1}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                   
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <strong><span style={{textAlign:"left"}}>{row?.name}</span></strong>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.priority ? row?.priority : "NA"}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <span style={{color:"blue","text-decoration":"underline","font-weight":"bold","cursor":"pointer"}} onClick={()=>{setMenu({'visible':1,'detail':row});loadMenuItems(row?.ID)}}>Items</span>
                                                                </td>
                                                                
                                                                <td style={{textAlign:"left"}}>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="p-2 font-16" title="Edit menu"> 
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => navigate('add?id=' + row.ID, { state: row.ID })}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete menu">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.ID)}></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5} id="noRecordMsg">Loading menus...</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            {menuInfo && menuInfo.visible ? (<>
                <>
            <CModal visible={menuInfo} backdrop={"static"} size={"xl"} onClose={() => setMenu({})} id='surveyModal'>
                <CModalHeader style={{ backgroundColor: "#", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                    <CModalTitle style={{ color: "#fff", width: "100%" }}>
                    {menuInfo?.title}
                        <span style={{ float: "right", cursor: "pointer" }} onClick={() => setMenu({})} > X </span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form  id="menuItemForm" className='row' onSubmit={submitMenuItem}>
                        <div id='point-message' style={{color:"red"}}>{errors?.saveMenu}</div>
                       
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label className="form-label mb-0">Menu Type 
                                    <span className="required_field">*{errors?.menuType}</span>
                                </label>
                                <select id="menuType" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => {(menuItem['menuType']=e.target.value);getMenuType(e.target.value)}} >
                                    <option value="" key={0}>Select Menu Type</option>
                                    <option value="1" selected={menuItem['menuType']==1?true:false} key={1}>Category</option>
                                    <option value="2" selected={menuItem['menuType']==2?true:false} key={2}>Link</option>
                                    <option value="3" selected={menuItem['menuType']==3?true:false} key={3}>Text</option>
                                    <option value="4" selected={menuItem['menuType']==4?true:false} key={4}>Product</option>
                                    <option value="5" selected={menuItem['menuType']==5?true:false} key={5}>Page</option>
                                    <option value="6" selected={menuItem['menuType']==6?true:false} key={6}>Blog</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label mb-0">Menu Refernce 
                                    <span className="required_field">*{errors?.menuReference}</span>
                                </label>
                                <select type="text" className="form-control" placeholder="Enter menu item name" id="objectId"  onChange={(e) => {setMenuTextAndLink(e);}} >

                                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label className="form-label mb-0">Menu Text 
                                    <span className="required_field">*{errors?.menuText}</span>
                                </label>
                                <input type="text" className="form-control" placeholder="Enter menu item name" id="menuText" defaultValue={menuItem?.menuText} onChange={(e) => (menuItem['menuText']=e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label className="form-label mb-0">Menu Link 
                                    <span className="required_field">*{errors?.menuLink}</span>
                                </label>
                                <input type="text" className="form-control" placeholder="Enter menu item name" id="menuLink" defaultValue={menuItem?.menuLink} onChange={(e) => (menuItem['menuLink']=e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="mb-3">
                                <label className="form-label mb-0">Menu Icon 
                                    <span className="required_field">*{errors?.menuIcon}</span>
                                </label>
                                <input type="text" className="form-control" placeholder="Enter menu item name" id="menuIcon" defaultValue={menuItem?.menuIcon} onChange={(e) => (menuItem.menuIcon=e.target.value)} />
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="mb-3">
                                <label className="form-label mb-0">Same Window 
                                    <span className="required_field">*{errors?.isBlank}</span>
                                </label>
                                <select id="isBlank" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => (menuItem.isBlank=e.target.value)} >
                                    <option value='' key={0}>Select</option>
                                    <option value={0} selected={menuItem['isBlank']==0?true:false} key={1}>Yes</option>
                                    <option value={1} selected={menuItem['isBlank']==1?true:false} key={2}>No</option>
                                </select>
                            </div>
                        </div>
                            
                         <div className="col-lg-3 mt-3" style={{"text-align":"right"}}>                                    
                            <div  style={{display:'inline-block','vertical-align':"top"}}>
                                <button type="submit" className="btn btn-primary mx-2" >Submit</button>
                            </div>
                            <div style={{display:'inline-block','vertical-align':"top"}}>
                                <label> &nbsp; </label>
                                <button type="button" className="btn btn-danger mx-2" id="cancelButton"  onClick={()=>{setMenu({});setMenuItem({}); setItemList([])}}>Cancel</button>
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <hr/>
                        <h5>Menu Items of <span style={{"color":"blue"}}>{menuInfo.detail.name}</span></h5>
                    <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Icon</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Menu Type</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Menu Text</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Link</th> 
                                                        <th scope="col" style={{textAlign:"left"}}>Same Window</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {itemLists ? (<>
                                                    {itemLists?.length > 0 ? (<>
                                                        {itemLists?.map((item, i) => (
                                                            
                                                            <tr key={"menu-item"+i} id={"menu-item"+i} onDragStart={(e) => dragStart(e, i)}
                                                            onDragEnter={(e) => dragEnter(e, i)}
                                                            onDragEnd={drop}
                                                            draggable>
                                                                <td>{i+1}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                <span>{item?.menuIcon}</span>
                                                                   
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <span>{item?.menuTypeText}</span>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                <span>{item?.menuText}</span>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                <span>{item?.menuLink}</span>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                <span>{(item?.isBlank==0)?"Yes":"No"}</span>
                                                                </td>
                                                                
                                                                <td style={{textAlign:"left"}}>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={item.status === 1 ? "checked" : ""} onChange={(e)=>updateItemStatus(item.menuID,item.ID, item.status)} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="p-2 font-16" title="Edit menu"> 
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => resetItemForm(item)}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete menu">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteItem(item.menuID,item?.ID)}></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            
                                                        ))}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5} id="noRecordMsg">Loading menus...</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                    </div>
                </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}


        </div>
    )
}

export default MenuList