import React, { useState, useEffect } from "react";
import { defaultPage } from "../../../Common/Constant";
import { customerList } from "../../../api/user";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Pagination from "../../Layout/Pagination";
import { getDownloadFile } from "../../../services/api.services";
import { FaDownload } from "react-icons/fa";

function CustomerList() {
  useEffect(() => {
    document.title = "Customer List - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await customerList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Customer List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item active">Customer</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {lists?.length && (
                <div className="download-icon" title="Download">
                  <div
                    className="download-excel"
                    onClick={() =>
                      getDownloadFile("/user/customer/download/?format=xlsx")
                    }
                  >
                    <FaDownload
                      color="#e92e26"
                      style={{
                        width: "25px",
                        height: "25px",
                        display: "flex",
                        marginRight: "8px",
                      }}
                    />
                    <div>Download</div>
                  </div>
                </div>
              )}
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    {/* <!-- end card header --> */}
                    <div className="card-body">
                      <table className="table table-striped table-hover table_client">
                        <thead>
                          <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Sr. No.</th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Full name
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Phone number
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Email address
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Status
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Registered on
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Last login
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {lists ? (
                            <>
                              {lists?.length > 0 ? (
                                <>
                                  {lists?.map((row, i) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {(pageInfo.current_page - 1) *
                                              pageInfo.perPage +
                                              ++i}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.firstName
                                              ? row?.firstName
                                              : "-"}
                                            {row?.lastName
                                              ? " " + row?.lastName
                                              : ""}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.phone ? row?.phone : "-"}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.email ? row?.email : "-"}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              textTransform: "capitalize",
                                              color:
                                                row?.statusSlugTxt ===
                                                "verified"
                                                  ? "green"
                                                  : "red",
                                            }}
                                          >
                                            {row?.statusTxt
                                              ? row?.statusTxt
                                              : "-"}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.createdOn
                                              ? row?.createdOn
                                              : "-"}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.lastLogin
                                              ? row?.lastLogin
                                              : "-"}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td colSpan={5}>No records found</td>
                                  </tr>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <Pagination pageInfo={pageInfo} changePage={changePage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CustomerList;
