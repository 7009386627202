import Swal from "sweetalert2";
import { get, postAPI } from "../services/api.services";
const { defaultPage } = require("../Common/Constant");

export const orderList = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  searchKeyword = "",
  orderStatus,
}) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    q: searchKeyword,
    orderStatus,
  };
  let res = await postAPI("/order/list", data);
  return res;
};

export const orderDetailsWithId = async ({ payload }) => {
  if (payload) {
    let res = await get("/order/details/" + payload);
    return res;
  }
};

export const buyersHistory = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  searchKeyword = "",
  id,
}) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    q: searchKeyword,
  };

  let res = await postAPI("/user/customer/" + id + "/orders/", data);
  return res;
};

export const orderUpdateStatus = async ({ id, status }) => {
  return Swal.fire({
    text: `Are you sure you want to ${
      status 
    }?`,
    confirmButtonText: "Yes",
    showCancelButton: true,
  }).then(async (result) => {
    if (result.value) {
      let res = await postAPI("/order/update-status/", {
        orderIDs: id,
        status: status,
      });
      return Swal.fire({
        text: res.message,
        timer: 1500,
      }).then(() => {
        return res;
      });
    }
  });
};
