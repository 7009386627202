import React, { useEffect, useState } from "react";
import { orderList } from "../../../api/orders";
import { defaultPage } from "../../../Common/Constant";
import Pagination from "../../Layout/Pagination";

function OrderDetails() {
  useEffect(() => {
    document.title = "Orders list - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await orderList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  return (
    <div className="row mt-2">
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          {/* <!-- end card header --> */}
          <div className="card-body">
            <table className="table table-striped table-hover table_client">
              <thead>
                <tr className="bg-primary" style={{ color: "white" }}>
                  <th scope="col">Sr. No.</th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Order number
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Payment method
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Delivery address
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Total items
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Total qty
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Order Amount breakup (In INR)
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Order status
                  </th>
                  <th scope="col" style={{ textAlign: "left" }}>
                    Created on
                  </th>
                </tr>
              </thead>

              <tbody>
                {lists ? (
                  <>
                    {lists?.length > 0 ? (
                      <>
                        {lists?.map((row, i) => {
                          return (
                            <>
                              <tr>
                                <td>
                                  {(pageInfo.current_page - 1) *
                                    pageInfo.perPage +
                                    ++i}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row?.orderNumber ? row?.orderNumber : "-"}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row?.paymentMethod
                                    ? row?.paymentMethod
                                    : "-"}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  Name:{" "}
                                  <b>
                                    {row?.deliveryDetails.name
                                      ? row?.deliveryDetails.name
                                      : "-"}
                                  </b>
                                  <br />
                                  Address:{" "}
                                  {row?.deliveryDetails.addressLine1
                                    ? row?.deliveryDetails.addressLine1
                                    : "-"}
                                  ,
                                  {row?.deliveryDetails.addressLine2
                                    ? row?.deliveryDetails.addressLine2
                                    : ""}
                                  <br />
                                  City:{" "}
                                  {row?.deliveryDetails.city
                                    ? row?.deliveryDetails.city
                                    : ""}
                                  <br />
                                  State:{" "}
                                  {row?.deliveryDetails.state
                                    ? row?.deliveryDetails.state
                                    : ""}{" "}
                                  (
                                  {row?.deliveryDetails.zipcode
                                    ? row?.deliveryDetails.zipcode
                                    : ""}
                                  )
                                  <br />
                                  Landmark:{" "}
                                  {row?.deliveryDetails.landmark
                                    ? row?.deliveryDetails.landmark
                                    : "Not available"}
                                  <br />
                                  Phone:{" "}
                                  {row?.deliveryDetails.phone
                                    ? row?.deliveryDetails.phone
                                    : "-"}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row?.totalItems ? row?.totalItems : "0"}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row?.totalQuantity
                                    ? row?.totalQuantity
                                    : "0"}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  Sub total:{" "}
                                  {row?.subTotal ? row?.subTotal : "-"}
                                  <br />
                                  Advance paid:{" "}
                                  {row?.advance ? row?.advance : "0"}
                                  <br />
                                  Balance to be collected:{" "}
                                  {row?.balance ? row?.balance : ""}
                                  <br />
                                  Shipping Amount:{" "}
                                  {row?.shipAmt ? row?.shipAmt : ""}
                                  <br />
                                  Grand total:{" "}
                                  <b>
                                    {row?.grandTotal ? row?.grandTotal : "-"}
                                  </b>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row?.status ? row?.status : "-"}
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  {row?.createdOn ? row?.createdOn : "-"}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={6}>No records found</td>
                        </tr>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
            <Pagination pageInfo={pageInfo} changePage={changePage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
