import { postAPI } from '../services/api.services';
import { defaultPage } from '../Common/Constant';

export const cartList = async ({ page = defaultPage.current_page, recPerPage = defaultPage.perPage }) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
  };
  let res = await postAPI('/cart/', data);
  return res;
};

export const cartTab = async ({ page = defaultPage.current_page, recPerPage = defaultPage.perPage, id }) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
  };
  let res = await postAPI('/cart-items/' + id, data);
  return res;
};
