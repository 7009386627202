import { useEffect } from 'react';
import { buyersHistory } from '../../../api/orders';
import { useState } from 'react';
import moment from 'moment/moment';
import { defaultPage } from '../../../Common/Constant';
import Pagination from '../../Layout/Pagination';

let tabNames = [
  {
    id: 0,
    name: 'Order Items',
  },
  {
    id: 1,
    name: 'Payments',
  },
  {
    id: 2,
    name: 'Buyer Details',
  },
  //   {
  //     id: 3,
  //     name: "Logistics",
  //   },
  {
    id: 3,
    name: 'Buyers History',
  },
];

function Tabs({ row, toggleState, toggleTab, orderData, tabId, userType }) {
  const deliveryDetails = orderData?.deliveryDetails;

  const [buyersHistoryData, setBuyersHistoryData] = useState({});
  const [pageInfo, setPageInfo] = useState({});

  useEffect(() => {
    if (toggleState === 3) {
      getBuyerHistoryWithId(row?.buyerUserDetailID);
    }
  }, [toggleState]);

  const getBuyerHistoryWithId = async (userId) => {
    let res = await buyersHistory({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: '',
      id: userId,
    });

    if (res.status === 1) {
      setPageInfo(res.paginationInfo);
    }

    setTimeout(() => {
      setBuyersHistoryData({
        ...buyersHistoryData,
        orderHistory: res.payload,
      });
    }, 1000);
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getBuyerHistoryWithId();
  };

  const { orderHistory } = buyersHistoryData;

  const getBuyerHistory = () => {
    return (
      <div className='row mt-0' style={{ width: '100%' }}>
        <div className='col-12'>
          <div className='card'>
            {/* <!-- end card header --> */}
            <div className='card-body'>
              <table className='table table-striped table-hover table_client'>
                <thead>
                  <tr className='bg-primary' style={{ color: 'white' }}>
                    <th scope='col'>Order ID</th>
                    <th scope='col'>Order Value</th>
                    <th scope='col'>Quantity</th>
                    <th scope='col'>Date of Purchase</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {orderHistory && orderHistory?.length ? (
                      <>
                        {orderHistory?.map((row, i) => {
                          return (
                            <tr key={i}>
                              <td>{row.orderNumber}</td>
                              <td>{row?.subTotal}</td>
                              <td>{row?.totalQuantity}</td>
                              <td>
                                {row?.createdOn ? (
                                  <div className='orderedtime'>
                                    {moment(
                                      row?.createdOn.substring(0, row?.createdOn.indexOf(' ')),
                                      'DD-MM-YYYY'
                                    ).format('DD MMM, YYYY')}
                                  </div>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={5}>Loading Buyers history...</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </table>
              <Pagination pagination={pageInfo} changePage={changePage} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='tab-container' key={row?.orderID}>
      <ul className={toggleState === null ? 'tab-list' : 'tab-list no-active-tab'}>
        {userType === 'guest'
          ? tabNames.slice(0, 3).map((tab, index) => {
              return (
                <li
                  className={`${row?.orderID === tabId && toggleState === tab.id ? 'tabs active-tabs' : 'tabs'}`}
                  id={tab.id}
                  key={tab.id}
                  tab={row?.orderID}
                  onClick={toggleTab}
                >
                  {tab.name}
                </li>
              );
            })
          : tabNames.map((tab, index) => {
              return (
                <li
                  className={`${row?.orderID === tabId && toggleState === tab.id ? 'tabs active-tabs' : 'tabs'}`}
                  id={tab.id}
                  key={tab.id}
                  tab={row?.orderID}
                  onClick={toggleTab}
                >
                  {tab.name}
                </li>
              );
            })}
      </ul>
      {row?.orderID === tabId ? (
        <div className='content-container'>
          <div className={`${toggleState === 0 ? 'order-items-tab' : 'order-remaining-tabs'}`}>
            <div
              className={`${toggleState === 0 ? 'first-tab-content active-content' : 'first-tab-content'} items-list`}
            >
              <div className='row ordered-items-list'>
                <p className='col-3 heading' style={{ width: '45%' }}>
                  Product
                </p>
                <p className='col-2 heading'>SKU/Combo code</p>
                <p className='col-1  heading'>Unit Price</p>
                <p className='col-2  heading'>
                  Quantity/ <br />
                  Subscription
                </p>
                <p className='col-1 heading'>Total</p>
              </div>

              {orderData?.orderedItems?.map((item, index) => {
                return (
                  <div className='row ordered-items-list' key={index}>
                    <p className='col-3' style={{ width: '45%' }}>
                      <img width={50} height={50} src={item?.images[0]} />
                      {item.variantName}
                    </p>
                    <p className='col-2'>{item?.skuDetails?.map((sku) => sku.skuCode)}</p>
                    <p className='col-1'>{item?.finalPrice}</p>
                    <p className='col-2'>{item?.quantity}</p>
                    <p className='col-1'>{item?.subTotal}</p>
                  </div>
                );
              })}
              <div className='price-details'>
                <p>Order Subtotal : ₹{orderData?.orderDetails?.subTotal}</p>
                <p>Shipping and handling : ₹{orderData?.orderDetails?.shipAmt}</p>
                <p className='total-price'>Total : ₹{orderData?.orderDetails?.subTotal}</p>
              </div>
            </div>
          </div>
          <div className={`${toggleState === 1 ? 'content active-content' : 'content'} payment-details`}>
            <div className='row'>
              <div className='col-4'>
                <p>
                  <b style={{ textDecoration: 'underline' }}>Payment Mode : </b>
                  {orderData?.orderDetails?.paymentMethod ? orderData?.orderDetails?.paymentMethod : '-'}
                </p>
                <p>
                  <b style={{ textDecoration: 'underline' }}> Payment Status : </b>
                  {orderData?.orderDetails?.paymentStatus ? orderData?.orderDetails?.paymentStatus : '-'}
                </p>
              </div>
              {orderData?.orderDetails?.paymentMethod === 'Razorpay' ? (
                <div className='col-4'>
                  <p>
                    <b style={{ textDecoration: 'underline' }}>
                      {' '}
                      Payment order number{' '}
                      {orderData?.orderDetails?.paymentMethod
                        ? '(' + orderData?.orderDetails?.paymentMethod + ')'
                        : ''}{' '}
                      :{' '}
                    </b>
                    {orderData?.orderDetails?.pgOrderNumber ? orderData?.orderDetails?.pgOrderNumber : '-'}
                  </p>
                  <p>
                    <b style={{ textDecoration: 'underline' }}>
                      {' '}
                      Payment Transaction ID{' '}
                      {orderData?.orderDetails?.paymentMethod
                        ? '(' + orderData?.orderDetails?.paymentMethod + ')'
                        : ''}{' '}
                      :{' '}
                    </b>
                    {orderData?.orderDetails?.paymentTransactionID
                      ? orderData?.orderDetails?.paymentTransactionID
                      : '-'}
                  </p>
                  <p>
                    <b style={{ textDecoration: 'underline' }}> Transaction ID (Clarion Audio): </b>
                    {orderData?.orderDetails?.transactionId ? orderData?.orderDetails?.transactionId : '-'}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={`${toggleState === 2 ? 'content active-content' : 'content'} buyer-details`}>
            <div style={{ width: '40%' }} className=''>
              <b style={{ textDecoration: 'underline' }}>Buyer Information:</b>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'> Name</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.name ? deliveryDetails?.name : '-'}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'> Email</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.email ? deliveryDetails?.email : '-'}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'> Mobile</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.phone ? deliveryDetails?.phone : '-'}</div>
              </div>
            </div>

            <div style={{ width: '60%' }} className=''>
              <b style={{ textDecoration: 'underline' }}>Shipping Address :</b>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'>Name</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.name ? deliveryDetails?.name : '-'}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'>Address</div>
                <div className='col-1'>:</div>
                <div className='col-4'>
                  {deliveryDetails?.addressLine1 ? deliveryDetails?.addressLine1 : '-'},
                  {deliveryDetails?.addressLine2 ? deliveryDetails?.addressLine2 : ''}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'>Landmark</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.landmark ? deliveryDetails?.landmark : 'Not available'}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'>City</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.city ? deliveryDetails?.city : ''}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'>State</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.state ? deliveryDetails?.state : ''}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'>Pincode</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.zipcode ? deliveryDetails?.zipcode : ''}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  lineHeight: '20px',
                }}
              >
                <div className='col-4 order-details-heading'>Phone</div>
                <div className='col-1'>:</div>
                <div className='col-4'>{deliveryDetails?.phone ? deliveryDetails?.phone : '-'}</div>
              </div>
            </div>
          </div>

          {userType !== 'guest' ? (
            <div className={toggleState === 3 ? 'content active-content' : 'content'}>{getBuyerHistory()}</div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default Tabs;
