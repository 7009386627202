import { get, postAPI, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');

export const brandList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/brand/list', data)
    return res;
}

export const brandDetails = async({payload}) => {
    let res = await get('/brand/details/'+payload);
    return res;
}

export const brandDelete = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/brand/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const brandUpdateStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/brand/update-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const brandCreate = async({payload}) => {
    let res = await postAPI('/brand/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const brandUpdate = async({payload}) => {
    let data = {
        name: payload.name,
        imageURL: payload.imageURL,
        priority: payload.priority,
        status: payload.status
    }
    let res = await putAPI('/brand/update/'+payload?.id, data)
    return res;
}

export const brandCheckName = async({name, id = 0}) => {
    let data = {
        name: name,
        id: id
    }
    let res = await postAPI('/brand/check-unique', data);
    return res;
}