import React, { useEffect, useState } from "react";
import { orderDetailsWithId, orderList, orderUpdateStatus } from "../../../api/orders";
import { defaultPage } from "../../../Common/Constant";
import Pagination from "../../Layout/Pagination";
import moment from "moment/moment";
import Tabs from "./Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from 'react-select';
import { masterListAPI } from "../../../api/masterList";
import { postAPI } from "../../../services/api.services";

const MySwal = withReactContent(Swal);

function OrderList({ orderStatus }) {
  useEffect(() => {
    document.title = "Orders list - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [masterList, setMasterList] = useState();
  const [ordersList, setOrdersList] = useState([]);
  const [selectedReason, setSelectedReason] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataStatus, setDataStatus] = useState(0);

  const [toggleState, setToggleState] = useState(null);
  const [tabId, setTabId] = useState();
  const [orderData, setOrderData] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const queryString = new URLSearchParams(location.search);
  const id = queryString.get("id");

  useEffect(() => {
    if (orderStatus) {
      setLoading(true);
      getList();
      setSelectedReason({})
      setOrdersList([])
      getMasterList()
    }
  }, [searchKeyword, orderStatus]);

  const getOrderedIdData = async () => {
    if (id !== null) {
      let res = await orderDetailsWithId({ payload: id });
      setOrderData({
        ...orderData,
        ["deliveryDetails"]: res.payload.deliveryDetails,
        ["orderedItems"]: res.payload.items,
        ["orderDetails"]: res.payload,
      });
    }
  };

  useEffect(() => {
    getOrderedIdData();
  }, [id]);

  const getOrderIdData = async () => {
    let res = await orderDetailsWithId({ payload: id });
  };

  useEffect(() => {
    if (lists) {
      getOrderIdData();
    }
  }, [lists]);

  const toggleTab = (e) => {
    setToggleState(Number(e.target.getAttribute("id")));
    setTabId(Number(e.target.getAttribute("tab")));
    if (Number(id) !== Number(e.target.getAttribute("tab"))) {
      navigate(`/orders/${orderStatus}?id=` + e.target.getAttribute("tab"));
    }
  };

  const getList = async () => {
    let res = await orderList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
      orderStatus: orderStatus ? orderStatus : "",
    });
    setDataStatus(res?.status);
    if (res.status === 1) {
      setTimeout(() => {
        setLists(res.payload);
        setLoading(false);
      }, 500);
      setPageInfo(res.paginationInfo);
    } else {
      setTimeout(() => {
        setLoading(false);
        setLists([]);
      }, 500);
    }
  };

  const getMasterList = async () => {
    let res = await masterListAPI({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
      orderStatus: orderStatus ? orderStatus : "",
    });
    if (res.status === 1) {
      setMasterList(res.payload

      );
      setPageInfo(res.paginationInfo);
    } else {
      setMasterList([]);
    }
  };

  const getButtonText = (status) => {
    if (status === 'Confirmed') {
      return 'Dispatch'
    }
    else if (status === 'Dispatched') {
      return 'Deliver'
    }
    else if (status === 'Placed') {
      return 'Confirm'
    }
  }

  const updateOrSetStatus = async (id, status) => {
    let res = await orderUpdateStatus({ id: [id], status });
    if (res && res.status === 1) {
      getList();
    }
  };

  const cancelOrder = async (e, orderID) => {
    e.preventDefault();
    setSelectedReason({})
    setOrdersList(selectedReason)
    let res = await MySwal.fire({
      title: "Please select the reason for cancellation",
      html: (<>
        <select className='order-cancel-reason' id="selectOption" data-choices data-choices-sorting="true">
          <option value={'select option'} key={0}>{'select option'}</option>
          {masterList?.map(option => {
            return <option value={option.ID} key={option.ID}>{option.name}</option>
          })}
        </select>
        <textarea rows={5} className="order-cancel-description" id='textaArea' placeholder='Please specify your issue here'
        />
      </>
      ),
      width: "40%",
      confirmButtonText: "Cancel Order",
      showCancelButton: true,
      preConfirm: () => {
        return {
          reasonID: Number(document.getElementById("selectOption").value),
          orderID: orderID,
          remarks: document.getElementById("textaArea").value
        }
      }
    }).then(async (result) => {
      if (result) {
        let res = await postAPI("/order/cancel", {
          orders: [result?.value]
        })
        return Swal.fire({
          text: res?.message,
          timer: 1500,
        }).then(() => {
          return res;
        });
      }
    });

    if (res && res.status === 1) {
      getList();
    }

  }

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };


  const OrderCard = ({ row }) => {
    return (
      <div className="order-card card">
        <div className="card-body card-alignment">
          {row.userType === 'guest' ? <b className="py-1 px-2 guest-user mb-2">guestUser </b>: null}
          <div className="ordered-user-details">
            <div>
              <div
                style={{
                  display: "flex",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                <div className="col-4 order-details-heading"> Order ID</div>
                <div className="col-1">:</div>
                <div className="col-10">
                  <b>{row?.orderNumber ? row?.orderNumber : "-"} </b>
                  &nbsp; | &nbsp; Payment: &nbsp;
                  <b> {row?.paymentMethod ? row?.paymentMethod : "-"} </b>
                  &nbsp; | &nbsp;
                  {row?.createdOn ? (
                    <div className="orderedtime">
                      {moment(
                        row?.createdOn.substring(
                          0,
                          row?.createdOn.indexOf(" ")
                        ),
                        "DD-MM-YYYY"
                      ).format("DD MMM, YYYY")}
                      -
                      {row?.createdOn.substring(
                        row?.createdOn.indexOf(" ") + 1
                      )}
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                <div className="col-4 order-details-heading"> Buyer</div>
                <div className="col-1">:</div>
                <div className="col-7">
                  {row?.deliveryDetails.name ? row?.deliveryDetails.name : "-"}-
                  {row?.deliveryDetails.phone
                    ? row?.deliveryDetails.phone
                    : "-"}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                <div className="col-4 order-details-heading"> Deliver To</div>
                <div className="col-1">:</div>
                <div className="col-7">
                  {row?.deliveryDetails.name ? row?.deliveryDetails.name : "-"}-
                  {row?.deliveryDetails.phone
                    ? row?.deliveryDetails.phone
                    : "-"}
                </div>
              </div>
            </div>
            <div>
              <button className="order-status each-order-status">
                {row?.status ? row?.status : "-"}
              </button>
              <button className="ordered-items total-items">
                ₹{row?.subTotal ? row?.subTotal : "-"} | &nbsp;
                {row?.totalItems ? row?.totalItems : "0"}{" "}
                {row?.totalItems > 1 ? "Items" : "Item"}
              </button>
              {row?.status !== 'Delivered' ?
                <div className="order-details">
                  {!['Cancelled', 'Failed'].includes(row?.status) ? (
                    <button
                      style={{ height: '35.47px' }}
                      className='order-confirmation'
                      onClick={() => updateOrSetStatus(row.orderID, getButtonText(row?.status).toLowerCase())}
                    >
                      {getButtonText(row?.status)}
                    </button>
                  ) : (
                    ''
                  )}
                  {row?.isCancellationAllowed ? (
                    <button className='order-cancellation' onClick={(e) => cancelOrder(e, row.orderID, ordersList)}>
                      Cancel
                    </button>
                  ) : (
                    ''
                  )}

                </div> : ''}
            </div>
          </div>
          <Tabs
            row={row}
            toggleState={toggleState}
            toggleTab={toggleTab}
            orderData={orderData}
            tabId={tabId}
            userType={row?.userType}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="row mt-2">
      <div className="col-xl-12 col-lg-12">
        <div>
          <div style={{ display: "flex" }} className="col-3">
            <div className="search-orderid" style={{ background: "#ffffff", width: "100%" }}>
              <input
                type="search"
                placeholder=" Search with Order ID"
                className="search-input"
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <img
                src="/assets/images/search.png"
                alt="search"
                width={18}
                height={18}
                style={{ marginTop: "1px", marginRight: "5px", float: "right", cursor: "pointer" }}
              />
            </div>
          </div>

          <div>
            {lists?.length && dataStatus === 1 ? (
              lists?.map((row, i) => {
                return <OrderCard row={row} />;
              })
            ) : (
              <>
                {loading ? (
                  <div className="page-loading">
                    <ReactLoading
                      type={"spin"}
                      color={"#E92E26"}
                      height={"50px"}
                      width={"50px"}
                    />
                  </div>
                ) : (
                  <div className="page-loading">
                    <tr>
                      <td colSpan={6}>No records found</td>
                    </tr>
                  </div>
                )}
              </>
            )}
            <Pagination pageInfo={pageInfo} changePage={changePage} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderList;