import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../../Common/Constant';
import { subscriberList } from '../../../api/user';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Pagination from '../../Layout/Pagination';
function SubscriberList() {

    useEffect(() => {
        document.title = "Subscriber List - Clarion Audio";
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getList();
    },[]);

    const getList = async() =>{
        let res = await subscriberList({page: pageInfo.current_page, recPerPage: defaultPage.perPage, searchKeyword: searchKeyword});
        if(res.status === 1){
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else{
            setLists([])
        }
    }

    const changePage = (p) => {
        pageInfo.current_page = p;
        getList();
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Subscriber List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item active">Subscriber</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Email address</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Subscribed For</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Subscribed On</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Unsubscribed On</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr>
                                                                <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++i}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.subscriber ? row?.subscriber : "-"}
                                                                </td>
                                                                <td style={{textAlign:"left", textTransform:"capitalize", color: row?.subscribedFor === "newsletter" ? "red" : "green"}}>
                                                                    {row?.subscribedFor ? row?.subscribedFor : "-"}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.subscribedOn ? row?.subscribedOn : "-"}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.unsubscribedOn ? row?.unsubscribedOn : "-"}
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5}>No records found</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SubscriberList