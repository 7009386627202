import React from 'react';
import { useEffect } from "react";
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';

function PageNotFound() {
    useEffect(() => {
        document.title = "Page not found - Clarion Audio"
    },);
    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">                            
                            <div className="row text-center mb-5 mt-5">
                                <div className="col-md-12">
                                    <img src="/assets/images/page-under-dev.png" alt="Page under development" className="img-fluid" style={{ maxWidth: "15%" }} />
                                    <h3 className='mt-2'>Page under development</h3>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer /> 
        </div>
    )
}

export default PageNotFound;