import React, { useState, useEffect } from 'react';
import * as Menu from '../../api/menu';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
 import { s3bucket } from '../../Common/Constant';
 import { uploadImageAPI } from '../../services/api.services';
import { useNavigate, useLocation } from "react-router-dom";

function MenuAdd() {
    const navigate = useNavigate();    
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id'); //console.log(id);       
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const Title = id===null?"Add":"Edit" ;
    useEffect(() => {
        document.title = Title +" Menu - Clarion Audio";
    },); 

    const getDetail = async () => {
        if(id !== null && id != 0 && id != ''){

                let res = await Menu.getDetails({payload:id})
                if(res.status === 1){
                    setForm({
                        ['name']: res.payload.name,
                        ['slug']: res.payload.slug,
                        ['priority']: res.payload.priority,
                        ['status']: res.payload.status,
                        ['imageUrl']: null//res.payload.imageURL
                    });
                    console.log(form);
                    console.log(res.payload);
                }
        }
    }    

    useEffect(() => {
        if(id !== null && id != 0 && id != ''){
        getDetail();
        }
    },[id]);
        

    const setField = (field, value) => {
        form[field]= value;
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const validateForm = () =>{
        const {parentID, name} = form;
        const newErrors = {};
        if(!name || name === '') newErrors.name = 'Please enter this field';
        if(!parentID || parentID === 'Select menu set') newErrors.parentID = 'Please select this field';
        return newErrors;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors();
        const formErrors = validateForm();
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // } else {
            let payload={
                name: form.name,
                parentID: form.parentID,
                imageURL: null,//form.imageURL && form.imageURL !== '' ? form.imageURL : null,
                priority: form.priority,
                status: form.status
            };
            let res = {};
            if(id && id != null && id!= '' && id != 0){
                payload.id = id;
                res = await Menu.update({payload});
            }else{
                res = await Menu.create({payload});
            }
            if(res.status === 1){
                navigate("/web/menu");
            }//  else {
            //     setErrors({...errors,['submit']:res.message})
            // }
        //}
        
    }

    const handleCancel = () => {
        navigate("/web/menu");
    }

    const handleChange = (e) => {
        checkFile(e.target.files, e.target.id.split('-')[1]);
    };
    const checkFile = async (files, id) => {
        if(files.length === 0) return;

        // let fileElement = files[0];
        // let fileExtension = "";
        // if (fileElement.name.lastIndexOf(".") > 0) {
        //     fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        // }
        let res = await uploadImageAPI('/upload/', files)
        if (res.status === 1) {
            let imageUploadedUrls = res.payload.url;
            let images = [];
            await imageUploadedUrls.map((imageUploadedUrl,i) => {
                 form['imageURL'] = imageUploadedUrl;
               // images[i] = imageUploadedUrl;
            });

            // setForm({
            //     ...form,
            //     ['images']:images
            // });
        }
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Add Menu</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Menu</li>
                                                <li className="breadcrumb-item active">Add Menu</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className='row'>
                                                <form onSubmit={handleSubmit}>
                                                    {/* <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field">{errors?.submit}</div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Menu Name 
                                                                    <span className="required_field">*{errors?.name}</span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="Enter menu name" id="name" defaultValue={form?.name} onChange={(e) => setField('name', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Priority</label>
                                                                <input type="number" className="form-control" placeholder="Enter priority" id="priority"
                                                                defaultValue={form?.priority} onChange={(e) => setField('priority', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Status</label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('status',e.target.value)}>
                                                                    <option value={0} key={0}>Inactive</option>
                                                                    <option value={1} key={1}>Active</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Image (Dimension: 200 X 200 pixels)</label>
                                                                <input type="file" className="form-control" id="menuFile" onChange={handleChange} multiple />
                                                            </div>
                                                            <img className="mt-3" id="imageView" src={form?.imageURL ? form?.imageURL : ""} style={{display:'none',height:"50px", border:"2px solid grey"}} />
                                                        </div> */}
                                                    {/* </div> */}
                                                    {/* <!--end row--> */}

                                                    {/* <div className="row text-end d-inline"> */}
                                                        <div className="col-md-3 mt-3">
                                                            <button type="button" className="btn btn-primary mx-2" id="submitButton" onClick={handleSubmit}>Submit</button>
                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default MenuAdd