import { get, postAPI, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');

export const getList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await get('/page/list', data)
    return res;
}

export const getDetails = async({payload}) => {
    let res = await get('/page/details/'+payload);
    return res;
}

export const trash = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/page/trash/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const updatePublish = async({id, publish}) => {
    let publishTxt = publish==1?'publish': 'unpublish';
    return Swal.fire({
        text: 'Are you sure you want to '+publishTxt+'?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/page/update-publish/'+id+'/'+publish)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const create = async({payload}) => {
    let res = await postAPI('/page/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const update = async({payload}) => {
    let ID = payload?.id;
    delete payload.id;
    let res = await putAPI('/page/update/'+ID, payload)
    return res;
}

