import { get, postAPI, putAPI, deleteAPI, downloadFileWithPostMethod, postUploadFile } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');


export const productList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/product/list', data)
    return res;
}

export const productDetails = async({payload}) => {
    let res = await get('/product/details/'+payload);
    return res;
}

export const productCreate = async({payload}) => {
    let res = await postAPI('/product/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const productUpdate = async({payload, id}) => {
    let res = await putAPI('/product/update/'+id, payload);
    return res;
}

export const productDelete = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/product/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const productUpdateStatus = async({id, productStatus}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/product/update-status/'+id+'/'+productStatus)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const relatedProductList = async({payload}) => {
    let res = await get('/product/related-product/'+payload.categoryID+'/'+payload.productID);
    return res;
}

export const updateRelatedProduct = async({payload}) => {
    let productID = payload?.productID;
    delete payload.productID;
    
    let res = await postAPI('/product/related-product/'+productID, payload);
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const getSavedRelatedProduct = async({payload}) => {
    let res = await get('/product/saved-related-product/'+payload?.productID);
    return res;
}

export const productCategoryUpdate = async({payload, id}) => {
    let res = await putAPI('/product/update/category/'+id, payload);
    return res;
};
export const getProductCategoryAttributes = async({payload}) => {
    let res = await get('/product/category-attributes/'+payload?.id);
    return res;
}

export const productSKUUpdate = async({payload, id}) => {
    let res = await putAPI('/product/update/sku/'+id, payload);
    return res;
};

export const getProductSKU = async({payload}) => {
    let res = await get('/product/sku/'+payload?.id);
    return res;
};

export const productContentUpdate = async({payload, id}) => {
    let res = await putAPI('/product/update/content/'+id, payload);
    return res;
};

export const getProductContent = async({payload}) => {
    let res = await get('/product/content/'+payload?.id);
    return res;
};

export const productSEOContentUpdate = async({payload, id}) => {
    let res = await putAPI('/product/update/seo-content/'+id, payload);
    return res;
};

export const getProductSEOContent = async({payload}) => {
    let res = await get('/product/seo-content/'+payload?.id);
    return res;
};

export const productWarrantyRegistrationList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await get('/product/warranty-registrations', data)
    return res;
}

export const downloadBulkInventory = async () => { 
    try {
        let requestPayload = {
            format: 'xlsx',
            fileName: 'product_list_'+Math.floor(new Date().getTime() / 1000)+'.xlsx'
        };
        let rsFile = await downloadFileWithPostMethod('/download-report/product/',requestPayload);
        return rsFile;
    } catch (error) {
        Swal.fire({
            text: "Inventory could not be downloaded.",
            timer: 1500,
            icon: "error"
        });
        return false;
    }
};

export const uploadProductList = async (file) => {
    const formData = new FormData();
    formData.append("excel", file);
  
    try {
      return await postUploadFile('/upload-report/product', formData);
    } catch (error) {
      Swal.fire({
        text: "Error uploading the product list.",
        timer: 1500,
        icon: "error",
      });
      return false;
    }
  };