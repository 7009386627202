import { get, postAPI, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');

export const categoryList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/category/list', data)
    return res;
}

export const categoryDetails = async({payload}) => {
    let res = await get('/category/details/'+payload);
    return res;
}

export const categoryDelete = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/category/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const categoryUpdateStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/category/update-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const categoryCreate = async({payload}) => {
    let res = await postAPI('/category/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const categoryUpdate = async({payload}) => {
    let ID = payload?.id;
    delete payload.id;

    let res = await putAPI('/category/update/'+ID, payload)
    return res;
}

export const categoryCheckName = async({name, id = 0}) => {
    let data = {
        name: name,
        id: id
    }
    let res = await postAPI('/category/check-unique', data);
    return res;
}

export const mappedCategoryAttributeSet = async({id}) => {
    let res = await get('/category/mapping/'+id);
    return res;
}

export const mappedCategoryAttributes = async({id}) => {
    let res = await get('/category/mapped-attributes/'+id);
    return res;
}

export const mapCategoryAttributeSet = async({id, attributeSetIDArr}) => {
    let data = {
        attributeSetIDArr: attributeSetIDArr
    };
    let res = await putAPI('/category/mapping/'+id, data);
    return res;
}

export const getParentCategoriesList = async() => {
    let res = await get('/get-parent-categories/');
    return res;
}