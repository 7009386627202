import { get, postAPI } from "../services/api.services";
const { defaultPage } = require("../Common/Constant");

export const masterListAPI = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  searchKeyword = "",
}) => {
  let data = {
    typeSlug: "cancellation-reason",
    page: page,
    recPerPage: recPerPage,
    q: searchKeyword,
    parent: ""
  };
  let res = await postAPI("/master-data/list", data);
  return res;
};
