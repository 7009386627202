import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../Common/Constant';
import { masterData } from '../../api/master-data';
import * as page from '../../api/page';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate } from "react-router-dom";
import { error } from 'jquery';

function PageList() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Page List - Clarion Audio";
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});

    

    const getList = async() =>{        
        let res = await page.getList({page:0, recPerPage: 50, searchKeyword: searchKeyword});
        if(res.status === 1){
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else{
            setLists([])
            //document.getElementById("noRecordMsg").innerHTML = "No page found";
            setErrors({...errors,['records']:"No page found"})

            
        }
    }

    useEffect(() => {
        getList();
    },[]);

    const updatePublish = async (id,publishedOn) => {
        let publish = (publishedOn !== null && publishedOn > 0) ? 0 : 1;
        let res = await page.updatePublish({id:id,publish:publish});
        if(res && res.status === 1){
            getList();            
        }
    }

    const deleteRow = async (id) => {
        let res = await page.trash({id:id})
        if(res && res.status === 1){
            getList()
        }
    }

    const changePage = (pageInfo) => {
        setPageInfo(pageInfo)
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Page List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Page</li>
                                                <li className="breadcrumb-item active">Page List</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
                                    <div className="col-2" style={{marginLeft: "10px", textAlign: "right"}}>
                                        <div className="form-group">
                                            <button className="btn btn-primary" onClick={() => navigate('/web/page/add')}> <i className="ri-add-line"></i> Add Page</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                       <th scope="col" style={{textAlign:"left"}}>Title</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Image</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Created Date</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Publish Date</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Publish</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr>
                                                                <td>{i+1}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <span style={{textAlign:"left"}}>{row?.title}</span>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                <img src ={(row?.leadImg!== null )? row?.leadImg: ""} style={{'height':'60px'}} />
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                   <span style={{textAlign:"left"}}>{row?.createdDate}</span>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {(row?.publishedDate != null &&
                                                                    <><span style={{textAlign:"left"}}>{row?.publishedDate}</span>
                                                                    </>)}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={(row.publishedOn !== null && row.publishedOn !== 0)? "checked" : ""} onChange={()=>updatePublish(row.ID, row.publishedOn)} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="p-2 font-16" title="Edit page"> 
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => navigate('add?id=' + row.ID, { state: row.ID })}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete page">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.ID)}></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5} id="noRecordMsg">{(error?.records)?error?.records:"Loading Pages..."}</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PageList