import React, { useCallback, useEffect, useState } from 'react';

import { defaultPage } from '../../../Common/Constant';
import { cartTab } from '../../../api/cart';

const tabName = [
  {
    id: 0,
    name: 'Cart Item',
  },
];

const CartTab = ({ id }) => {
  const [activeTab, setActiveTab] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [product, setProduct] = useState([]);

  const fetchCartTab = useCallback(
    async (userId) => {
      let res = await cartTab({ page: pageInfo.current_page, recPerPage: defaultPage.perPage, id: userId });
      if (res.status === 1) {
        setProduct(res.payload);
        setPageInfo(res.paginationInfo);
      }
    },
    [pageInfo.current_page]
  );

  useEffect(() => {
    if (id && activeTab !== null) {
      fetchCartTab(id);
    }
  }, [fetchCartTab, id, activeTab]);

  return (
    <div className='tab-container'>
      <ul className={activeTab === null ? 'tab-list' : 'tab-list no-active-tab'}>
        {tabName.map((tab) => (
          <li
            key={tab.id}
            className={activeTab === tab.id ? ' tabs active-tabs' : 'tabs'}
            onClick={() =>
              setActiveTab((prev) => {
                if (prev === null) {
                  return tab.id;
                }
                return null;
              })
            }
          >
            {tab.name}
          </li>
        ))}
      </ul>
      {activeTab === 0 && (
        <div className='content-container'>
          <div className={`${activeTab === 0 ? 'order-items-tab' : 'order-remaining-tabs'}`}>
            <div className={`${activeTab === 0 ? 'first-tab-content active-content' : 'first-tab-content'} items-list`}>
              <div className='row ordered-items-list'>
                <p className='col-3 heading' style={{ width: '45%' }}>
                  Product
                </p>
                <p className='col-2 heading'>SKU</p>
                <p className='col-1 heading'>Unit Price</p>
                <p className='col-2 heading'>Quantity</p>
                <p className='col-1 heading'>Total</p>
              </div>
              {product?.items?.map((item, i) => {
                return (
                  <div className='row ordered-items-list' key={i}>
                    <p className='col-3' style={{ width: '45%' }}>
                      <img width={50} height={50} src={item?.image[0]} alt='Product' />
                      <span className='ps-1'>{item.title}</span>
                    </p>
                    <p className='col-2'>{item.skuCode}</p>
                    <p className='col-1'>{item.unitPrice}</p>
                    <p className='col-2'>{item.quantity}</p>
                    <p className='col-1'>{item.total}</p>
                  </div>
                );
              })}
              <div className='price-details'>
                <p>Order Subtotal : ₹{product.orderSubtotal}</p>
                <p>Shipping and handling : ₹{product.shippingHandling}</p>
                <p className='total-price'>Total : ₹{product.total}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartTab;
