import { get, postAPI, putAPI, deleteAPI } from "../services/api.services";
import Swal from "sweetalert2";
const { defaultPage } = require('../Common/Constant');

export const getList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = "", parentID = 0}) => {
    let data = {
        parentID: parentID,
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/menu/list', data)
    return res;
}

export const getDetails = async({payload}) => {
    let res = await get('/menu/details/'+payload);
    return res;
}

export const trash = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/menu/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const updateStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/menu/update-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const create = async({payload}) => {
    let res = await postAPI('/menu/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const update = async({payload}) => {
    let data = {
        parentID: payload.parentID,
        name: payload.name,
        imageURL: payload.imageURL,
        priority: payload.priority,
        status: payload.status
    }
    let res = await putAPI('/menu/update/'+payload?.id, data)
    return res;
}

export const menuCheckName = async({name, id = 0}) => {
    let data = {
        name: name,
        id: id
    }
    let res = await postAPI('/menu/check-unique', data);
    return res;
}

export const createItem = async({payload}) => {
    let res = await postAPI('/menu/add-item', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const updateItem = async({payload}) => {
    let data = {
        menuID: payload.menuID,
        menuType: payload.menuType,
        menuText: payload.menuText,
        menuLink: payload.menuLink,
        menuIcon: payload.menuIcon,
        isBlank: payload.isBlank,
        //imageURL: payload.imageURL,
        //priority: payload.priority,
        status: payload.status
    }
    let res = await putAPI('/menu/update-item/'+payload?.ID, data)
    return res;
}

export const getItemList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = "", menuID = 0}) => {
    let data = {
        menuID: menuID,
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await get('/menu/item-list', data)
    return res;
}

export const updateItemStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/menu/update-item-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const trashItem = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/menu/delete-item/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const updateItemPriority = async(data) => {   
    let res = await postAPI('/menu/update-item-priority/', data)
    return res;
}