import React, { useState, useEffect } from "react";
import { defaultPage } from "../../../Common/Constant";
import { storeLocator } from "../../../api/user";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Pagination from "../../Layout/Pagination";
import { dealerList } from "../../../api/dealer";
import Swal from "sweetalert2";
import swal2 from "sweetalert2/dist/sweetalert2.js";

import { postAPI } from "../../../services/api.services";
import withReactContent from "sweetalert2-react-content";
import { Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";

const MySwal = withReactContent(Swal);

function StoreLocator() {
  useEffect(() => {
    document.title = "Contact List - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [userId, setUserId] = useState();
  const [dealersList, setDealersList] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [dealersPageInfo, setDealersPageInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    dealersPageInfo.current_page = 1
    getDealersList();
  }, [searchKeyword]);

  useEffect(() => {
    getList();
  }, []);

  const handleClose = () => {
    setSearchKeyword("");
    dealersPageInfo.current_page = 0;
    setShow(false);
  };
  const handleShow = (id) => {
    setUserId(id);
    getDealersList();
    setSearchKeyword("");
    setShow(true);
  };

  const getList = async () => {
    let res = await storeLocator({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
    });
    if (res?.status === 1) {
      setLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const getDealersList = async () => {
    let dealerResponse = await dealerList({
      page: dealersPageInfo.current_page,
      recPerPage: defaultPage.perPage,
      status: "authorized",
      searchKeyword: searchKeyword,
    });

    if (dealerResponse?.status === 1) {
      setDealersList(
        dealerResponse.payload.map((item) => {
          return { ...item, isChecked: false };
        })
      );
      setDealersPageInfo(dealerResponse.paginationInfo);
    } else {
      setDealersList([]);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  const changeDealersPage = (p) => {
    dealersPageInfo.current_page = p;
    getDealersList();
  };

  const getResponse = async () => {
    let res = await postAPI("/crm/store-locator/send-store-details", {
      ID: userId,
      dealers: dealersList
        ?.map((item) => {
          if (item?.isChecked) {
            return item?.ID;
          }
        })
        .filter((item) => item !== undefined),
    });
    return Swal.fire({
      text: res.message,
      timer: 1500,
    }).then(() => {
      return res;
    });
  };

  const getPagination = ()=> {
    return <ReactPaginate
    breakLabel="..."
    nextLabel="next >"
    onPageChange={(e) => changeDealersPage(e.selected + 1)}
    pageCount={ dealersPageInfo?.totalPages}
    previousLabel="< previous"
    renderOnZeroPageCount={null}
    breakClassName={"page-item"}
    breakLinkClassName={"page-link"}
    containerClassName={"pagination"}
    pageClassName={"page-item"}
    pageLinkClassName={"page-link"}
    previousClassName={"page-item"}
    previousLinkClassName={"page-link"}
    nextClassName={"page-item"}
    nextLinkClassName={"page-link"}
    activeClassName={"active"} 
    />
  }

  const getDealersListModal = () => {
    return (
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Please select the dealers you want to send to customer
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getResponse();
            }}
            id="editmodal"
            className="w-full max-w-sm"
          >
            <>
              <div
                className="search-orderid"
                style={{ background: "#ffffff", width: "30%" }}
              >
                <input
                  type="search"
                  placeholder=" Search with Name"
                  className="search-input"
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
                <img
                  src="/assets/images/search.png"
                  alt="search"
                  width={18}
                  height={18}
                  style={{
                    marginTop: "1px",
                    marginRight: "5px",
                    float: "right",
                    cursor: "pointer",
                  }}
                />
              </div>

              <table className="table table-striped table-hover table_client">
                <thead>
                  <tr
                    className="bg-primary"
                    style={{ color: "white", fontSize: "14px" }}
                  >
                    <th scope="col">Sr. No.</th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Select
                    </th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Name
                    </th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Phone number
                    </th>
                    <th scope="col" style={{ textAlign: "left" }}>
                      Email address
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {dealersList?.length ?
                    dealersList?.map((row, i) => {
                      return (
                        <tr style={{ fontSize: "14px" }}>
                          <td>
                            {(dealersPageInfo.current_page - 1) *
                              dealersPageInfo.perPage +
                              ++i}
                          </td>
                          <td>
                            <input
                              className="swal2-checkbox"
                              type="checkbox"
                              onChange={(e) => {
                                setDealersList(
                                  dealersList?.map((dealerItem) => {
                                    if (dealerItem.ID === row.ID) {
                                      dealerItem.isChecked = e.target.checked;
                                    }
                                    return dealerItem;
                                  })
                                );
                              }}
                            />
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {row?.distributorName ? row?.distributorName : "-"}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {row?.phone ? row?.phone : "-"}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {row?.email ? row?.email : "-"}
                          </td>
                        </tr>
                      );
                    }) :   
                    <tr>
                     <td colSpan={5}>No records found</td>
                  </tr>}
                </tbody>
              </table>
            </>
          </form>

          <div className="pagination-styles">
            <div>
              Showing {(dealersPageInfo.current_page - 1) * dealersPageInfo.perPage + 1} to {dealersPageInfo.current_page * dealersPageInfo.perPage > dealersPageInfo.total
                ? dealersPageInfo.total
                : dealersPageInfo.current_page * dealersPageInfo.perPage} of {dealersPageInfo.total} records
            </div>
            {getPagination()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="modal-add-button"
            onClick={handleClose}
            form="editmodal"
          >
            Add
          </button>
          <button className="modal-cancel-button" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Store Locator</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Store Locator
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    {/* <!-- end card header --> */}
                    <div className="card-body">
                      <table className="table table-striped table-hover table_client">
                        <thead>
                          <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Sr. No.</th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Location
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Mobile/Email address
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Registered on
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Status
                            </th>
                            {/* <th scope="col" style={{ textAlign: "left" }}>
                              Action
                            </th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {lists ? (
                            <>
                              {lists?.length > 0 ? (
                                <>
                                  {lists?.map((row, i) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {(pageInfo.current_page - 1) *
                                              pageInfo.perPage +
                                              ++i}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.location
                                              ? row?.location
                                              : "-"}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.userIdentity
                                              ? row?.userIdentity
                                              : "-"}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.createdOn
                                              ? row?.createdOn
                                              : "-"}
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              color:
                                                row?.status === "Pending"
                                                  ? "red"
                                                  : "green",
                                            }}
                                          >
                                            {row?.status &&
                                            row?.status === "Pending" ? (
                                              <i className="mdi mdi-danger text-danger me-1"></i>
                                            ) : (
                                              <i className="mdi mdi-checkbox-marked-circle-outline text-success me-1"></i>
                                            )}

                                            {row?.status ? row?.status : "-"}
                                          </td>
                                          {/* <td style={{ textAlign: "left" }}>
                                            <button
                                              className="order-confirmation"
                                              onClick={() => handleShow(row.ID)}
                   
                                            >
                                              Show dealers
                                            </button>
                                          </td> */}
                                        </tr>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td colSpan={5}>No records found</td>
                                  </tr>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <Pagination pageInfo={pageInfo} changePage={changePage} />
                      {getDealersListModal()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default StoreLocator;
