import { get, postAPI } from "../services/api.services";
const { defaultPage } = require("../Common/Constant");

export const ecommerceAnalytics = async (getUrl) => {
  let res = await get(getUrl);
  return res;
};

export const websiteAnalytics = async (getUrl) => {
  let res = await get(getUrl);
  return res;
};

export const trafficAnalytics = async (getUrl) => {
  let res = await get(getUrl);
  return res;
};
