import { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  rolesList,
  uniqueUser,
  userRoleMapping,
  userUpdate,
  usersPrefetchingList,
} from "../../../api/accessManagemet";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { defaultPage } from "../../../Common/Constant";

const rolesData = [
  {
    id: 0,
    name: "Super Admin",
    isChecked: false,
  },
  {
    id: 1,
    name: "Admin",
    isChecked: false,
  },
  {
    id: 2,
    name: "Customer Support",
    isChecked: false,
  },
];

function EditModal({ id, setUniqueStatus }) {
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [rolesDetails, setRolesDetails] = useState(rolesData);
  const [selectedRole, setSelectedRole] = useState();
  const [listOfRoles, setListOfRoles] = useState();
  const [roleMappingError, setRoleMappingError] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getPrefilledUserData = async () => {
    let res = await usersPrefetchingList({ payload: id });
    if (res.status === 1) {
      setUserDetails({
        ["ID"]: res.payload.ID,
        ["firstName"]: res.payload.firstName,
        ["lastName"]: res.payload.lastName,
        ["email"]: res.payload.email,
        ["mobile"]: res.payload.phone,
        ["activeRole"]: res.payload?.role?.ID,
      });
      setSelectedRole(userDetails?.activeRole);
    }
  };

  const getListOfRoles = async () => {
    let res = await rolesList({});
    if (res.status === 1) {
      setListOfRoles(res.payload);
    } else {
      setListOfRoles([]);
    }
  };

  const checkUniqueness = async (userDetails) => {
    let roleUpdateRes;
    if (selectedRole) {
      let res = await uniqueUser(userDetails);
      setUniqueStatus({ status: res.status, message: res.message });
      if (res.status) {
        roleUpdateRes = await userUpdate(id, {
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          password: userDetails?.password,
        });
        if (roleUpdateRes.status) {
          setUniqueStatus({ status: res.status, message: "" });
        }
      }
    }
    if (selectedRole) {
      setRoleMappingError(false);
      let roleMappingRes = await userRoleMapping(id, { roleID: selectedRole });
      if (roleMappingRes.status && roleUpdateRes.status) {
        Swal.fire({
          text: `${roleMappingRes.message} and ${roleUpdateRes.message}`,
          timer: 2000,
        });
      }
      handleClose();
    } else {
      setRoleMappingError(true);
    }
  };

  useEffect(() => {
    if (selectedRole) {
      setRoleMappingError(false);
    } else {
      setRoleMappingError(true);
    }
  }, [selectedRole]);

  useEffect(() => {
    getPrefilledUserData();
    getListOfRoles();
  }, [show]);

  const getInputField = (name, text, targetName, placeholderText) => {
    return (
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading">
            {text}
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 input-styles border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder={placeholderText}
            name={targetName}
            disabled={targetName === "mobile" || targetName === "email"}
            type="text"
            value={name}
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                [e.target.name]: e.target.value,
              });
            }}
          />
        </div>
      </div>
    );
  };

  const getDropDown = () => {
    return (
      <select
        className="form-select"
        onChange={(e) => setSelectedRole(e.target.value)}
      >
        <option value={"0"} disabled selected key={0}>
        Select a Role
        </option>
        {listOfRoles?.map((role) => {
          return (
            <option
              value={role.ID}
              key={role.ID}
              selected={role.ID === userDetails?.activeRole ? true : false}
            >
              {role.name}
            </option>
          );
        })}
      </select>
    );
  };
  return (
    <>
      <Modal show={show} onHide={handleClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              checkUniqueness(userDetails);
              // setRolesDetails(rolesDetails);
              // setLists([...lists, { id: uuidv4(), name: moduleName }]);
            }}
            id="editmodal"
            className="w-full max-w-sm"
          >
            <div className="pb-3 d-flex">
              {getInputField(
                userDetails?.firstName,
                "First Name",
                "firstName",
                "Add First Name"
              )}
              <div style={{ marginLeft: "20px" }}>
                {getInputField(
                  userDetails?.lastName,
                  "Last Name",
                  "lastName",
                  "Add Last Name"
                )}
              </div>
            </div>
            <div className="pb-3 d-flex">
              {getInputField(userDetails?.email, "Email", "email", "Add Email")}{" "}
              <div style={{ marginLeft: "20px" }}>
                {getInputField(
                  userDetails?.mobile,
                  "Mobile",
                  "mobile",
                  "Add Mobile Number"
                )}
              </div>
            </div>
            <div className="pb-3 d-flex">
              {getInputField(
                userDetails?.password,
                "Password",
                "password",
                "Add password"
              )}
            </div>
            <div className="md:flex md:items-center mb-6 w-50">
              <div className="md:w-1/3">
                <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading">
                  Select a Role
                </label>
              </div>
              <div className="md:w-2/3">{getDropDown()}</div>
            </div>
            {roleMappingError ? (
              <div style={{ color: "#E92E26" }}> Please select a Role</div>
            ) : (
              ""
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-add-button" form="editmodal">
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <i className="ri-edit-2-line pointerC" onClick={handleShow} />
    </>
  );
}
export default EditModal;
