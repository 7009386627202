import { Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

function AddModal({
  show,
  handleClose,
  lists,
  setLists,
  moduleName,
  setModuleName,
  name,
  ref,
}) {
  return (
    <Modal
      ref={ref}
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setModuleName("");
            setLists([...lists, { id: uuidv4(), name: moduleName }]);
          }}
          id="editmodal"
          className="w-full max-w-sm"
        >
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading"
                for="name"
              >
                Module Name
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                className="bg-gray-200 input-styles border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="name"
                placeholder="Analytics"
                type="text"
                value={moduleName}
                onChange={(e) => {
                  setModuleName(e.target.value);
                }}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="modal-add-button"
          onClick={handleClose}
          form="editmodal"
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddModal;
