import React from "react";
import { useEffect } from "react";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import moment from "moment/moment";
import { trafficAnalytics } from "../api/analytics";
import { useState } from "react";
import ReactLoading from "react-loading";
import { useBetween } from "use-between";
import useShareableState from "../Common/useShareableState";
import { getModules } from "../api/auth";
import styled from "styled-components";


Chart.register(...registerables);

const dataPointsCard = [
    {
        id: 0,
        name: "Download - home catalogue",
        color: "orange",
        count: 0,
        description: "Total values till date",
    },
    {
        id: 1,
        name: "Download - pro catalogue",
        color: "lightseagreen",
        count: 0,
        description: "Total values till date",
    }
];

const CountStyles = styled.div`
  color: ${(props) => props.color};
  font-size: 24px;
  font-weight: 600;
`;

const AnalyticsCard = styled.div`
  background: #ffffff;
  padding: 6px 10px 10px 20px;
  min-width: 25%;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
  border-radius: 5px;
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    left: 0px;
    top: 20%;
    height: 65%;
    border-radius: 20px;
    margin-left: -3px;
    border-left: 8px solid ${(props) => props.color};
  }
`;

function TrafficAquisitionAnalyticsPage() {
    const [trafficAnalyticsData, setTrafficAnalyticsData] = useState();
    const [pageScreenAnalyticsData, setPageScreenAnalyticsData] = useState();
    const [dataPoints, setDataPoints] = useState(dataPointsCard);
    const [dataPointAnalytics, setDataPointAnalytics] = useState();
    
    const [loading, setLoading] = useState(true);
    const { setModulesList } = useBetween(useShareableState);
    
    const currentDate = new Date();
    const yyyy = currentDate.getFullYear();
    const mm = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const dd = String(currentDate.getDate()).padStart(2, '0');
    let todayFormatted = `${yyyy}-${mm}-${dd}`;
    let firstDayOfMonth = `${yyyy}-${mm}-01`;

    if (dd === '01') {
        // If current date is the first day of the month
        const lastMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0); // Last day of last month
        const lastMonthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1); // First day of last month
        const lastMonthStartFormatted = `${lastMonthStart.getFullYear()}-${String(lastMonthStart.getMonth() + 1).padStart(2, '0')}-${String(lastMonthStart.getDate()).padStart(2, '0')}`;
        const lastMonthEndFormatted = `${lastMonthEnd.getFullYear()}-${String(lastMonthEnd.getMonth() + 1).padStart(2, '0')}-${String(lastMonthEnd.getDate()).padStart(2, '0')}`;
        
        firstDayOfMonth = lastMonthStartFormatted;
        todayFormatted = lastMonthEndFormatted;
    } else {
        // If current date is not the first day of the month
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1); // Get yesterday's date
        const yesterdayFormatted = `${yesterday.getFullYear()}-${String(yesterday.getMonth() + 1).padStart(2, '0')}-${String(yesterday.getDate()).padStart(2, '0')}`;
        
        todayFormatted = yesterdayFormatted;
    }

    const [dateFrom, setDateFrom] = useState(firstDayOfMonth);
    const [dateTo, setDateTo] = useState(todayFormatted);

    useEffect(() => {
        document.title = "Traffic Acquisition Analytics - Clarion Audio";
    });

    const getModuleDetails = async () => {
        let res = await getModules();
        if (res?.status) {
            setModulesList(res?.payload);
        }
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
            getModuleDetails();
        }
    }, [])

    const options = {
        responsive: true,
        aspectRatio: 3,
        backgroundColor: "green",
        plugins: {
            legend: {
                position: "top",
                labels: {
                    font: {
                        size: 16,
                    },
                },
            },
        },
    };


    const fetchAnalyticsData = async (getUrl) => {
        let trafficAnalyticsRses = await trafficAnalytics(getUrl);
        if (trafficAnalyticsRses?.status === 1) {
            if(trafficAnalyticsRses.payload && Object.keys(trafficAnalyticsRses.payload).length > 0) {
                setLoading(false);
                setTrafficAnalyticsData(trafficAnalyticsRses.payload?.trafficAnalytics);
                setPageScreenAnalyticsData(trafficAnalyticsRses.payload?.pageScreenAnalytics);
                setDataPointAnalytics(trafficAnalyticsRses.payload?.dataPointAnalytics);
            } else {
                setLoading(false);
                setTrafficAnalyticsData();
                setPageScreenAnalyticsData();
                setDataPointAnalytics();
            }
        } else {
            setLoading(false);
            setTrafficAnalyticsData();
            setPageScreenAnalyticsData();
            setDataPointAnalytics();
        }
    };

    const handleApply = async () => {
        if (!dateFrom || !dateTo) {
            alert('Please select date range');
            return;
        }
        // Convert dates to Unix timestamps at the correct times
        const fromDate = new Date(dateFrom);
        fromDate.setHours(0, 0, 0, 0);
        const fromTimestamp = Math.floor(fromDate.getTime() / 1000);

        const toDate = new Date(dateTo);
        toDate.setHours(23, 59, 59, 999);
        const toTimestamp = Math.floor(toDate.getTime() / 1000);
        if (fromTimestamp > toTimestamp) {
            alert('Please select valid date range');
            return;
        }
        const apiUrl = `/analytics/traffic/?from=${fromTimestamp}&to=${toTimestamp}`;

        setLoading(true);
        await fetchAnalyticsData(apiUrl);
    };

    const getAnalyticsData = async () => {
        await fetchAnalyticsData('/analytics/traffic');
    };
    useEffect(() => {
        getAnalyticsData();
    }, []);


    var labels = [];
    var today = new Date();
    for (var i = 0; i < 16; i++) {
        var date = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - i * 2
        );
        labels.push(moment(date).format("MMM DD YYYY"));
    }
    labels.push(0);


    const trafficAnalyticsGraphData = {
        labels: [
            0,
            trafficAnalyticsData?.map((item) => item.source),
        ].flat(),
        datasets: [
            {
                label: "Total views",
                data: [
                    0,
                    trafficAnalyticsData?.map((item) => Number(item.value)),
                ].flat(),
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };

    useEffect(() => {
        const webData = dataPoints.map((object) => {
            return {
                ...object,
                dataCount:
                    object.id === 0
                    ? parseInt(dataPointAnalytics?.totalClicksOnHomeCatalogue)
                    : parseInt(dataPointAnalytics?.totalClicksOnProCatalogue)
            };
        });

        setDataPoints(webData);
    }, [dataPointAnalytics]);

    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row text-center">
                                <div className="col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Traffic Acquisition</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">Home</li>
                                                <li className="breadcrumb-item">Analytics</li>
                                                <li className="breadcrumb-item active">Traffic Acquisition</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="page-loading">
                                <ReactLoading
                                    type={"spin"}
                                    color={"#E92E26"}
                                    height={"50px"}
                                    width={"50px"}
                                />
                            </div>
                        ) : (
                            <>

                                <div className="pb-5 pt-3 px-4">
                                    <div className="row g-3">
                                        <div className="col-lg-5"></div>
                                        <div className="col-lg-7">
                                            <div className="row g-2 justify-content-end">
                                                <div className="col-5">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon1">From</span>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="dateFrom"
                                                            aria-label="dateFrom"
                                                            min='2023-01-01'
                                                            max={new Date().toISOString().split('T')[0]}
                                                            value={dateFrom}
                                                            onChange={(e) => setDateFrom(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="input-group mb-3">
                                                        <span className="input-group-text" id="basic-addon2">To</span>
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="dateTo"
                                                            aria-label="dateTo"
                                                            min='2023-01-01'
                                                            max={new Date().toISOString().split('T')[0]}
                                                            value={dateTo}
                                                            onChange={(e) => setDateTo(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    <div className="input-group mb-3">
                                                        <button className="btn btn-outline-primary w-100" onClick={handleApply}>Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="analytics-website row g-3" style={{ justifyContent: "left" }}>
                                        {dataPoints.map((card) => {
                                            return (
                                                <>
                                                    <div className="col-md-6 col-xl-4">
                                                        <AnalyticsCard
                                                            key={card.id}
                                                            // className="analytics-clarion-audio"
                                                            color={card.color}
                                                        >
                                                            <CountStyles color={card.color}>
                                                                {card.dataCount}{" "}
                                                            </CountStyles>
                                                            <div className="analytics-type">
                                                                {card.name.toUpperCase()}
                                                            </div>
                                                            <p>{card.description}</p>
                                                        </AnalyticsCard>
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </div>

                                    <Bar options={options} data={trafficAnalyticsGraphData} />

                                    {/* Page Screen Analytics */}
                                    <div className="row mt-5">
                                        <div className="col-md-6">
                                            <div className="card">
                                                <h4 className="card-title p-3">Page Screen Analytics</h4>
                                                <div className="card-body">
                                                    <div className="">
                                                        <table className="table table-hover w-100"style={{ display: "block", height: "500px", overflowY: "auto", scrollbarWidth: "thin", scrollbarColor: "rgb(148, 148, 148) transparent" }}>
                                                            <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "white" }}>
                                                                <tr>
                                                                    <th scope="col" style={{ maxWidth: "500px" }}>Title</th>
                                                                    <th scope="col">Path</th>
                                                                    <th scope="col">Count</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {pageScreenAnalyticsData.length > 0 ? pageScreenAnalyticsData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td style={{ maxWidth: "500px", overflow: "hidden" }}>{item.title}</td>
                                                                        <td>{item.path}</td>
                                                                        <td>{item.value}</td>
                                                                    </tr>
                                                                )) : (
                                                                    <tr>
                                                                        <td colSpan="3" className="text-center">No data found</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TrafficAquisitionAnalyticsPage;
