import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../../Common/Constant';
import { orderList } from '../../../api/orders';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Pagination from '../../Layout/Pagination';
import OrderDetails from './OrderDetails'
import OrderList from './OrderList';

function OrdersListPage({orderStatus}) {
    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Orders</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item active">Orders list</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                        {/* <OrderDetails />  //old code*/} 
                        <OrderList orderStatus={orderStatus}/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OrdersListPage