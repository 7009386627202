import React, { useState, useEffect } from "react";
import { defaultPage } from "../../../Common/Constant";
import { productWarrantyRegistrationList } from "../../../api/product";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Pagination from "../../Layout/Pagination";
import moment from "moment/moment";

function WarrantyRegistrationList() {
  useEffect(() => {
    document.title = "Warranty registration List - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    let res = await productWarrantyRegistrationList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Product Warranty Registration</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item active">
                          Warranty Registration
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  {/* <!-- end card header --> */}
                  <>
                    {lists ? (
                      <>
                        {lists?.length > 0 ? (
                          <>
                            {lists?.map((row, i) => {
                              return (
                                <>
                                  <div className="order-card card">
                                    <div className="card-body card-alignment">
                                      <div className="warranty-details">
                                        <div className="row">
                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Name
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.name ? row?.name : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Phone
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.phone ? row?.phone : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Email
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.email ? row?.email : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Address
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.address
                                                ? row?.address
                                                : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              State
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.state ? row?.state : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              PIN Code
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.zipcode
                                                ? row?.zipcode
                                                : "-"}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row">
                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Product Name / No
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.product
                                                ? row?.product
                                                : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Serial No
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.serialNo
                                                ? row?.serialNo
                                                : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Purchase Date
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.purchaseDate
                                                ? row?.purchaseDate
                                                : "-"}
                                            </div>
                                          </div>

                                          <div
                                            style={{
                                              display: "flex",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            <div className="col-3 order-details-heading">
                                              Created On
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-8">
                                              {row?.createdOn ? (
                                                <div className="orderedtime">
                                                  {moment(
                                                    row?.createdOn.substring(
                                                      0,
                                                      row?.createdOn.indexOf(
                                                        " "
                                                      )
                                                    ),
                                                    "DD-MM-YYYY"
                                                  ).format("DD MMM, YYYY")}
                                                  -
                                                  {row?.createdOn.substring(
                                                    row?.createdOn.indexOf(
                                                      " "
                                                    ) + 1
                                                  )}
                                                </div>
                                              ) : (
                                                "-"
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <tr>
                              <td colSpan={6}>No records found</td>
                            </tr>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                  <Pagination pageInfo={pageInfo} changePage={changePage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default WarrantyRegistrationList;
