import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../Common/Constant';
import { blogCategoryList, blogCategoryDelete, blogCategoryUpdateStatus } from '../../api/blog-category';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import Pagination from '../Layout/Pagination';
import { useNavigate } from "react-router-dom";

function BlogCategoryList() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Blog Blog Category List - Clarion Audio";
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getList();
    },[]);

    const getList = async() =>{
        let res = await blogCategoryList({page:0, recPerPage: 50, searchKeyword: searchKeyword});
        if(res.status === 1){
            setLists(res.payload)
            setPageInfo(res.paginationInfo)
        } else{
            setLists([])
        }
    }

    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await blogCategoryUpdateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    const deleteRow = async (id) => {
        let res = await blogCategoryDelete({id:id})
        if(res && res.status === 1){
            getList()
        }
    }

    const changePage = (pageInfo) => {
        setPageInfo(pageInfo)
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Blog Category List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Blog Category</li>
                                                <li className="breadcrumb-item active">Blog Category List</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
                                    <div className="col-2" style={{marginLeft: "10px", textAlign: "right"}}>
                                        <div className="form-group">
                                            <button className="btn btn-primary" onClick={() => navigate('add')}> <i className="ri-add-line"></i> Add Blog Category</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Name</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Parent name</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Priority</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr>
                                                                <td>{i+1}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <strong><span style={{textAlign:"left"}}>{row?.name}</span></strong>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.parent?.name ? row?.parent?.name : "-"}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.priority ? row?.priority : "-"}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="p-2 font-16" title="Edit blog category"> 
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => navigate('edit?id=' + row.ID, { state: row.ID })}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete blog category">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.ID)}></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5}>No blog category found</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pagination={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BlogCategoryList