import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  modulesList,
  roleMapping,
  roleUpdate,
  rolesPrefetchingList,
  uniqueRole,
} from "../../../api/accessManagemet";
import Swal from "sweetalert2";
import { masterData } from "../../../api/master-data";
import { defaultPage } from "../../../Common/Constant";
import Pagination from "../../Layout/Pagination";

function EditModal({ id, uniqueStatus, setUniqueStatus }) {
  const [show, setShow] = useState(false);
  const [roleDetails, setRoleDetails] = useState([
    {
      ID: "",
      name: "",
      modules: [{ ID: 0, name: "", actions: [{ permission: false }] }],
    },
  ]);

  const [modulesLists, setModulesLists] = useState();
  const [masterList, setMasterList] = useState();
  const [pageInfo, setPageInfo] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getModulesList = async () => {
    let res = await modulesList({
      page: pageInfo.current_page,
      recPerPage: 250,
    });
    if (res.status === 1) {
      setModulesLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setModulesLists([]);
    }
  };

  const getMasterList = async () => {
    let res = await masterData({ typeSlug: "actions" });
    if (res.status === 1) {
      setMasterList(
        res?.payload
          ?.sort((a, b) => a.ID - b.ID)
          .map((item) => {
            return {
              actionID: item.ID,
              actionName: item.name,
              actionSlug: item.slug,
              permission: 0,
            };
          })
      );
    }
  };

  const getPrefilledRoleData = async () => {
    let res = await rolesPrefetchingList({ payload: id });
    if (res.status === 1) {
      setRoleDetails({
        ["ID"]: res.payload.ID,
        ["name"]: res.payload.name,
        ["modules"]: res.payload.modules.length
          ? res.payload.modules
              ?.sort((a, b) => a.ID - b.ID)
              .map((item) => {
                return {
                  ...item,
                  actions: item?.actions?.length
                    ? item?.actions?.map((abc) => {
                        return {
                          ...abc,
                          actionID: `${abc?.actionID}+${item.ID}`,
                        };
                      })
                    : masterList,
                };
              })
          : modulesLists &&
            modulesLists?.map((abc) => {
              return {
                ...abc,
                actions: masterList
                  ?.sort((a, b) => a.actionID - b.actionID)
                  .map((listItem) => {
                    return {
                      ...listItem,
                      actionID: `${listItem?.actionID}+${abc.ID}`,
                    };
                  }),
              };
            }),
      });
    }
  };

  const updateRoleMapping = async (roleDetails) => {
    const data = Array.from(roleDetails?.modules)?.map((module) => {
      return {
        ID: module.ID,
        actions: module?.actions
          ?.sort(
            (a, b) =>
              Number(a?.actionID?.split("+")[0]) -
              Number(b?.actionID?.split("+")[0])
          )
          ?.map((action) => {
            return {
              ID: Number(action?.actionID?.split("+")[0]),
              permission: action.permission,
            };
          }),
      };
    });
    let res = await roleMapping(roleDetails.ID, { modules: data });
    if (res.status) {
      Swal.fire({
        text: res.message,
        timer: 1500,
      });
    } else {
      Swal.fire({
        text: res.message,
        timer: 1500,
      });
    }
    handleClose();
  };

  const checkUniqueness = async (roleDetails) => {
    let res = await uniqueRole(roleDetails);
    setUniqueStatus({ status: res.status, message: res.message });
    if (res.status) {
      let roleUpdateRes = await roleUpdate(id, {
        name: roleDetails?.name,
        status: 1,
        priority: 0,
        parentID: 0,
      });
      Swal.fire({
        text: roleUpdateRes.message,
        timer: 1500,
      });
      setUniqueStatus({ status: res.status, message: "" });
    }
  };

  useEffect(() => {
    getPrefilledRoleData();
    getMasterList();
    getModulesList();
  }, [show]);

  const changePage = (p) => {
    pageInfo.current_page = p;
    getModulesList();
  };

  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              checkUniqueness(roleDetails);
              updateRoleMapping(roleDetails);
            }}
            id="editmodal"
            className="w-full max-w-sm"
          >
            <div className="md:flex md:items-center mb-6">
              <div className="mb-3">
                <div className="md:w-1/3">
                  <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading">
                    Role Name
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    className="bg-gray-200 input-styles border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="name"
                    placeholder="Update Role Name"
                    type="text"
                    value={roleDetails?.name}
                    onChange={(e) => {
                      setRoleDetails({ ...roleDetails, name: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "20%" }} className="input-heading">
                  Module
                </div>
                <div style={{ display: "flex", width: "80%" }}>
                  {masterList?.map((item) => {
                    return (
                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        key={item.actionID}
                        className="input-heading"
                      >
                        {item.actionName}
                      </div>
                    );
                  })}
                </div>
              </div>
              {roleDetails?.modules
                ?.sort((a, b) => a.ID - b.ID)
                ?.map((module) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        padding: "3px 0 3px 0",
                        marginTop: "8px",
                        //  borderBottom: '1px solid #D3D3D3'
                      }}
                      key={module.ID}
                    >
                      <div style={{ display: "flex", width: "20%" }}>
                        <input
                          type="checkbox"
                          className="role-checkbox"
                          value={module.ID}
                          defaultChecked={!module.status}
                          onChange={(e) => {
                            setRoleDetails({
                              ID: roleDetails?.ID,
                              name: roleDetails?.name,
                              modules: roleDetails?.modules
                                ?.sort((a, b) => a.ID - b.ID)
                                ?.map((item) => {
                                  return {
                                    ID: item?.ID,
                                    name: item?.name,
                                    actions: item?.actions?.map(
                                      (actionItem) => {
                                        if (
                                          (actionItem?.actionID?.split("+")[1]) ===
                                          e.target.value.toString()
                                        ) {
                                          actionItem["permission"] = e
                                            .target.checked
                                            ? 1
                                            : 0;
                                        }
                                        return actionItem;
                                      }
                                    ),
                                  };
                                }),
                            });
                          }}
                        />
                        <span style={{marginLeft:"6px"}}>{module.name}</span>
                      </div>

                      <div style={{ display: "flex", width: "80%" }}>
                        {module?.actions
                          ?.sort(
                            (a, b) =>
                              Number(a?.actionID?.split("+")[0]) -
                              Number(b?.actionID?.split("+")[0])
                          )
                          ?.map((action) => {
                            return (
                              <div
                                style={{
                                  width: "25%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                                key={action.actionID}
                              >
                                <input
                                  type="checkbox"
                                  className="role-checkbox"
                                  checked={action?.permission ? true : false}
                                  onChange={(e) => {
                                    setRoleDetails({
                                      ID: roleDetails?.ID,
                                      name: roleDetails?.name,
                                      modules: roleDetails?.modules
                                        ?.sort((a, b) => a.ID - b.ID)
                                        ?.map((item) => {
                                          return {
                                            ID: item?.ID,
                                            name: item?.name,
                                            actions: item?.actions?.map(
                                              (actionItem) => {
                                                if (
                                                  actionItem?.actionID ===
                                                  action?.actionID
                                                ) {
                                                  actionItem["permission"] = e
                                                    .target.checked
                                                    ? 1
                                                    : 0;
                                                }
                                                return actionItem;
                                              }
                                            ),
                                          };
                                        }),
                                    });
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}

              {/* <div style={{ color: "#E92E26" }}>{uniqueStatus?.message}</div> */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
        {/* <Pagination
          pageInfo={pageInfo}
          changePage={changePage}
        /> */}
          <button className="modal-add-button" form="editmodal">
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <i className="ri-edit-2-line pointerC" onClick={handleShow} />
    </>
  );
}
export default EditModal;
