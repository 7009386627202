import React, { useState, useEffect } from 'react';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown';
import { productCreate } from '../../../api/product';
import { masterData } from '../../../api/master-data';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
// import { s3bucket } from '../../../Common/Constant';
// import { uploadImageAPI } from '../../../services/api.services';
import { useNavigate } from "react-router-dom";

function ProductAdd() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Add Product - Clarion Audio";
    },);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [brands, setBrands] = useState();
    const [tags, setTags] = useState();

    useEffect(() => {
        getBrands();
        getTags();
    },[]);
    
    const getBrands = async() => {
        let res = await masterData({typeSlug: 'brand', pagePerRec: 500});
        if(res.status === 1){
            setBrands(res.payload);
        }
    }

    const getTags = async() => {
        let res = await masterData({typeSlug: 'tag', pagePerRec: 500});
        if(res.status === 1){
            setTags(res.payload);
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const validateForm = () =>{
        const {productType, title, brandID } = form;
        const newErrors = {};
        if(!productType || productType === 'Select product type') newErrors.productType = 'Please select product type';
        if(!title || title === '') newErrors.title = 'Please enter this field';
        if(!brandID || brandID === 'Select brand') newErrors.brandID = 'Please select brand';
        return newErrors;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors();
        const formErrors = validateForm();
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        } else {
            let productTags = null;
            if(form?.tags?.length > 0){
                form?.tags?.map((tag, index) => {
                    if(index === 0){
                        productTags = tag.name;
                    } else {
                        productTags = productTags + ',' + tag.name;
                    }
                });
            }
            let res = await productCreate({
                payload: {
                    productType: form?.productType,
                    productStatus: form?.productStatus,
                    title: form?.title,
                    description: form?.description && form?.description !== "" ? form?.description.toString('html') : null,
                    brandID: form?.brandID,
                    tags: productTags,
                    publishOnWebsite: form?.publishOnWebsite ? 1 : 0
                }
            });
            if(res.status === 1){
                navigate("/product/edit?id="+res.payload.productID+"&tab=categoryInfo");
            }//  else {
            //     setErrors({...errors,['submit']:res.message})
            // }
        }
        
    }

    const handleCancel = () => {
        navigate("/product/list");
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Add Product</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a>Home</a></li>
                                                <li className="breadcrumb-item">Product</li>
                                                <li className="breadcrumb-item active">Add Product</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body p-5">
                                            <div className="row gx-5">
                                                <aside className="col-lg-3 border-end">
                                                    <div className="d-flex mb-4">
                                                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                            <button className="nav-link text-start p-3 active" id="v-pills-generalInfo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-generalInfo" type="button" role="tab" aria-controls="v-pills-generalInfo" aria-selected="true" title="Product General Informations">General</button>

                                                            <button className="nav-link text-start p-3" id="v-pills-categoryInfo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-categoryInfo" type="button" role="tab" aria-controls="v-pills-categoryInfo" aria-selected="false" title="Please save product to update category info" disabled>Category info</button>

                                                            <button className="nav-link text-start p-3" id="v-pills-skuDetails-tab" data-bs-toggle="pill" data-bs-target="#v-pills-skuDetails" type="button" role="tab" aria-controls="v-pills-skuDetails" aria-selected="false" title="Please save product to update SKU details" disabled>
                                                                SKU
                                                                <br />
                                                                <small>(Pricing, Images and Inventory)</small>
                                                            </button>

                                                            <button className="nav-link text-start p-3" id="v-pills-productContent-tab" data-bs-toggle="pill" data-bs-target="#v-pills-productContent" type="button" role="tab" aria-controls="v-pills-productContent" aria-selected="false" title="Please save product to update product content" disabled>Product Content</button>

                                                            <button className="nav-link text-start p-3" id="v-pills-seoDetails-tab" data-bs-toggle="pill" data-bs-target="#v-pills-seoDetails" type="button" role="tab" aria-controls="v-pills-seoDetails" aria-selected="false" title="Please save product to update SEO details" disabled>SEO Details</button>
                                                            
                                                            <button className="nav-link text-start p-3" id="v-pills-relatedItems-tab" data-bs-toggle="pill" data-bs-target="#v-pills-relatedItems" type="button" role="tab" aria-controls="v-pills-relatedItems" aria-selected="false" title="Please save product to map related items" disabled>Related Items</button>
                                                        </div>
                                                    </div>
                                                </aside>
                                                <div className="col-lg-9">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        {/* Product general info */}
                                                        <div className="tab-pane fade show active content-body p-xl-4" id="v-pills-generalInfo" role="tabpanel" aria-labelledby="v-pills-generalInfo-tab">
                                                            <div className="row gx-2">
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="productType" className="form-label">Product type <span className="required_field">*</span></label>
                                                                        <select className="form-select" id="productType" onChange={(e) => setField('productType', e.target.value)}>
                                                                            <option value="" key={0}>Select product type</option>
                                                                            <option value="simple" key={1}>Simple</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="productStatus" className="form-label">Product status <span className="required_field">*</span></label>
                                                                        <select className="form-select" id="productStatus" onChange={(e) => setField('productStatus', e.target.value)}>
                                                                            <option value="inactive" key={0}>Inactive</option>
                                                                            <option value="active" key={1}>Active</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-4">
                                                                <label htmlFor="title" className="form-label">Product name <span className="required_field">*</span></label>
                                                                <input type="text" placeholder="Enter product name" className="form-control" id="title" onChange={(e) => setField('title', e.target.value)} />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label htmlFor="description" className="form-label">Description</label>
                                                                {/* <textarea className="form-control" id="description" rows="4" onChange={(e) => setField('description', e.target.value)}></textarea> */}
                                                                <ReactQuill theme="snow" id="description" onChange={(e) => setField('description', e)} />
                                                            </div>
                                                            
                                                            <div className="row gx-2">
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="brandID" className="form-label">Brand <span className="required_field">*</span></label>
                                                                        <select className="form-select" id="brandID" onChange={(e) => setField('brandID', e.target.value)}>
                                                                            <option value="" key={0}>Select brand</option>
                                                                            {brands?.map((brand, index) => (
                                                                                <option value={brand.ID} key={index+1}>{brand.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="productTaxID" className="form-label">Tax ID</label>
                                                                        <input type="text" placeholder="Enter Tax ID" className="form-control" id="productTaxID" onChange={(e) => setField('productTaxID', e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-4">
                                                                <label htmlFor="tags" className="form-label">Tags</label>
                                                                {/* <input type="text" placeholder="Enter tags (comma separated)" className="form-control" id="tags" onChange={(e) => setField('tags', e.target.value)} /> */}

                                                                <Multiselect
                                                                    options={tags} // Options to display in the dropdown
                                                                    selectedValues={form?.tags} // Preselected value to persist in dropdown
                                                                    onSelect={(e) => setField('tags', e)} // Function will trigger on select event
                                                                    onRemove={(e) => setField('tags', e)} // Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                />
                                                            </div>

                                                            <label className="form-check mb-4 form-switch">
                                                                <input className="form-check-input" type="checkbox" role="switch" id="publishOnWebsite" onChange={(e) => setField('publishOnWebsite', e.target.checked)} />
                                                                <span className="form-check-label">
                                                                    Publish this product on website
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer bg-white text-end">
                                            <div className="col-md-12">
                                                <button type="button" className="btn btn-primary mx-2" id="submitButton" onClick={handleSubmit}>Submit</button>
                                                <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ProductAdd