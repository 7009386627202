import { get, postAPI } from "../services/api.services";

export const validateToken = async() => {
    let res = await postAPI('/auth/check-token/')
    return res;
}

export const getModules = async() => {
    let res = await get('/auth/get-modules');
    return res;
}