import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from "react-quill";

import { blogCategoryList } from '../../api/blog-category';
import { masterData } from '../../api/master-data';
import * as blog from '../../api/blog';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
// import { s3bucket } from '../../Common/Constant';
import { uploadImageAPI } from '../../services/api.services';

import { useNavigate, useLocation } from "react-router-dom";

function BlogAdd() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id'); //console.log(id);
    const Title = id===null?"Add":"Edit" ;
    useEffect(() => {
        document.title = Title +" Blog - Clarion Audio";
    },);

    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [blogCategory, setBlogCategory] = useState();
    const dragItem = useRef();
    const dragOverItem = useRef();

    useEffect(() => {
        getBlogCategory();
    },[])

    const getBlogCategory = async() => {
        let res = await blogCategoryList({page:0, recPerPage: 0});
        if(res.status === 1){
            setBlogCategory(res.payload)
        }
    }


    const getDetail = async () => {
        if(id !== null && id != 0 && id != ''){

                let res = await blog.getDetails({payload:id})
                if(res.status === 1){
                    setForm({
                        ...form,
                        ['categoryID']: res.payload.categoryID,
                        ['title']: res.payload.title,
                        ['subTitle']: res.payload.subTitle,
                        ['body']: res.payload.body,
                        ['priority']: res.payload.priority,
                        ['publish']: (res.payload.publishedOn === null || res.payload.publishedOn == 0)? 0:1,
                        ['images']: [res.payload.leadImg]
                    });
                    console.log(form);
                    console.log(res.payload);
                }
            }
        }    

    useEffect(() => {
        if(id !== null && id != 0 && id != ''){
        getDetail();
        }
    },[id]);

    const setField = (field, value) => {
        form[field]=value;
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const handleChange = (e) => {
        checkFile(e.target.files, e.target.id.split('-')[1]);
    };
    const checkFile = async (files, id) => {
        if(files.length === 0) return;

        // let fileElement = files[0];
        // let fileExtension = "";
        // if (fileElement.name.lastIndexOf(".") > 0) {
        //     fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        // }
        let res = await uploadImageAPI('/upload/', files)
        if (res.status === 1) {
            let imageUploadedUrls = res.payload.url;
            let images = [];
            await imageUploadedUrls.map((imageUploadedUrl,i) => {
                // form['images'].push(imageUploadedUrl);
                images[i] = imageUploadedUrl;
            });

            setForm({
                ...form,
                ['images']:images
            });
        }
    }

    const removeImage = (index) => {
        form['images'].splice(index, 1);
        setForm({
            ...form,
            ['images']: form['images']
        });
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const dragEnter = (e, position) => {
        dragOverItem.current = position;
        console.log(e.target.innerHTML);
      };
     
      const drop = (e) => {
        const copyListItems = [...form.images];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
       // setList(copyListItems);
        setForm({
            ...form,
            ['images']: copyListItems
        });
      };

    const validateForm = () =>{
        const {categoryID, title} = form;
        const newErrors = {};
        if(!title || title === '') newErrors.title = 'Please enter this field';
        if(!categoryID || categoryID === 'Select blog set') newErrors.categoryID = 'Please select this field';
        return newErrors;
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors();
        const formErrors = validateForm();
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        } else {
            let payload={
                categoryID: form.categoryID,
                title: form.title,
                subTitle: form.subTitle,
                images: form.images && form.images.length > 0 ? form.images : [],
                body: form.body,
                priority: form.priority,
                publish: form.publish
            }
            let res = {};
            if(id && id != null && id!= '' && id != 0){
                payload.id = id;
                res = await blog.update({payload});
            }else{
                res = await blog.create({payload});
            }
            if(res.status === 1){
                navigate("/web/blog");
            }//  else {
            //     setErrors({...errors,['submit']:res.message})
            // }
        }
        
    }

    const handleCancel = () => {
        navigate("/web/blog");
    }

    const Quillmodules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','link'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['clean'],
            [ 'image'],
          ],
      };

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">{Title} Blog</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Blog</li>
                                                <li className="breadcrumb-item active">{Title} Blog</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className='row'>
                                                <form onSubmit={handleSubmit}>
                                                    {/* <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3 required_field">{errors?.submit}</div>
                                                        </div>
                                                    </div> */}
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Blog Category <span className="required_field">*{errors?.categoryID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('categoryID',e.target.value)}>
                                                                    <option value={""} key={0}>Select blog category</option>
                                                                    {blogCategory?.map((prAttr) => {
                                                                        return (<option value={prAttr.ID} key={prAttr.ID} selected={prAttr.ID == form?.categoryID?true:false}>{prAttr.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Blog Title 
                                                                    <span className="required_field">*{errors?.title}</span>
                                                                </label>
                                                                <input type="text" className="form-control" placeholder="Enter blog title" id="title" defaultValue={form?.title} onChange={(e) => setField('title', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Blog Sub Title 
                                                                    <span className="">{errors?.subTitle}</span>
                                                                </label>
                                                                {/* <input type="text" className="form-control" placeholder="Enter blog sub title" id="subTitle" defaultValue={form?.subTitle} onChange={(e) => setField('subTitle', e.target.value)} /> */}
                                                                <ReactQuill theme="snow" id="subTitle" onChange={(e) => setField('subTitle', e)} value={form?.subTitle} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="mb-4">
                                                                <label htmlFor="body" className="form-label">Body/Content</label>
                                                                {/* <textarea className="form-control" id="body" rows="4" onChange={(e) => setField('body', e.target.value)}></textarea> */}
                                                                <ReactQuill modules={Quillmodules} theme="snow" id="body" onChange={(e) => setField('body', e)} value={form?.body}  />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-sm-4">
                                                            <div className="mb-4">
                                                                <label className="form-label mb-0">
                                                                    Image <small>(Max file size is 5MB)</small>
                                                                </label>
                                                                <input type="file" className="form-control" id="imageFile-0" onChange={handleChange} multiple />

                                                                <div className="row gx-2 mt-2 d-flex align-items-center justify-content-start" id="imagePreview-0" >
                                                                    {form?.images?.map((img, imgIndx) => (                                                                                
                                                                        <div className="col-md-3 mt-3" key={imgIndx} id={"img-"+imgIndx} 
                                                                        onDragStart={(e) => dragStart(e, imgIndx)}
                                                                                onDragEnter={(e) => dragEnter(e, imgIndx)}
                                                                                onDragEnd={drop}
                                                                                draggable >                                                                                    
                                                                            <div className="card mb-2 ms-2" style={{maxWidth: "100px"}}>
                                                                                <img src={img}  />

                                                                                <button type="button" className="btn btn-danger btn-sm" style={{position: "absolute", top: "-10px", right: "-10px"}} 
                                                                                onClick={() => {
                                                                                    removeImage(imgIndx);
                                                                                }} >
                                                                                    x
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Priority</label>
                                                                <input type="number" className="form-control" placeholder="Enter priority" id="priority"
                                                                 onChange={(e) => setField('priority', e.target.value)} defaultValue={form?.priority} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label mb-0">Publish</label>
                                                                <select id="publish" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('publish',e.target.value)} defaultValue={form?.publish}>
                                                                    <option value={0} key={0} selected={(form.publish == 0)? true:false}>No</option>
                                                                    <option value={1} key={1}selected={(form.publish != 0)? true:false} >Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* <!--end row--> */}

                                                    <div className="row text-end d-inline">
                                                        <div className="col-md-12">
                                                            <button type="button" className="btn btn-primary mx-2" id="submitButton" onClick={handleSubmit}>Submit</button>
                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default BlogAdd