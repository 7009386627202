import React, { useState, useEffect } from "react";
import { defaultPage } from "../../../Common/Constant";
import { dealerList, dealerUpdateStatus } from "../../../api/dealer";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import ReactLoading from "react-loading";
import ReactPaginate from "react-paginate";

function DealerList({ status }) {
  useEffect(() => {
    document.title = "Dealer List - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [dataStatus, setDataStatus] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setLists([]);
    setLoading(true);
    getList(status);
  }, [status]);

  const getList = async () => {
    let res = await dealerList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      status,
      searchKeyword: searchKeyword,
    });
    setDataStatus(res?.status);
    if (res?.status === 1) {
      if (lists?.length) {
        setLists(res.payload);
        setLoading(false);
      } else {
        setTimeout(() => {
          setLists(res.payload);
          setLoading(false);
        }, 2000);
      }
      setPageInfo(res.paginationInfo);
    } else {
      setTimeout(() => {
        setLoading(false);
        setLists([]);
      }, 2000);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  const updateOrSetStatus = async (id, status) => {
    let res = await dealerUpdateStatus({ id: id, status });
    if (res && res.status === 1) {
      getList();
    }
  };

  const getDealerDetails = (row) => {
    if (status === "requested") {
      return (
        <div className="dealer-order-details">
          <button
            className="order-confirmation"
            onClick={() => updateOrSetStatus(row.ID, "authorized")}
          >
            Approve
          </button>
          <button
            className="order-cancellation"
            onClick={() => updateOrSetStatus(row.ID, "unauthorized")}
          >
            Cancel
          </button>
        </div>
      );
    }
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Dealer List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item active">Dealer</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <>
                    {lists?.length && dataStatus === 1 ? (
                      <>
                        {lists?.map((row, i) => {
                          return (
                            <>
                              <div className="order-card card">
                                <div className="card-body card-alignment">
                                  <div className="warranty-details">
                                    <div style={{ width: "50%" }}>
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          <div className="col-4 order-details-heading">
                                            Distributor Name
                                          </div>
                                          <div className="col-1">:</div>
                                          <div>
                                            {row?.distributorName
                                              ? row?.distributorName
                                              : "-"}
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          <div className="col-4 order-details-heading">
                                            Dealership Name
                                          </div>
                                          <div className="col-1">:</div>
                                          <div>
                                            {row?.dealerName
                                              ? row?.dealerName
                                              : "-"}
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          <div className="col-4 order-details-heading">
                                            No. of years in business
                                          </div>
                                          <div className="col-1">:</div>
                                          <div>
                                            {row?.noOfYearsInBusiness
                                              ? row?.noOfYearsInBusiness
                                              : "-"}
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          <div className="col-4 order-details-heading">
                                            GSTIN
                                          </div>
                                          <div className="col-1">:</div>
                                          <div>
                                            {row?.gstin ? row?.gstin : "-"}
                                          </div>
                                        </div>

                                        <div
                                          style={{
                                            display: "flex",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          <div className="col-4 order-details-heading">
                                            Address
                                          </div>
                                          <div className="col-1">:</div>
                                          <div>
                                            {row?.address ? row?.address : "-"}
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        <div className="col-3 order-details-heading">
                                          Contact No
                                        </div>
                                        <div className="col-1">:</div>
                                        <div>
                                          {row?.phone ? row?.phone : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        <div className="col-3 order-details-heading">
                                          Email Address
                                        </div>
                                        <div className="col-1">:</div>
                                        <div>
                                          {row?.email ? row?.email : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        <div className="col-3 order-details-heading">
                                          Shop floor area
                                        </div>
                                        <div className="col-1">:</div>
                                        <div>
                                          {row?.shopArea ? row?.shopArea : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        <div className="col-3 order-details-heading">
                                          Interested In
                                        </div>
                                        <div className="col-1">:</div>
                                        <div>
                                          {row?.intereset
                                            ? row?.intereset
                                            : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        <div className="col-3 order-details-heading">
                                          State
                                        </div>
                                        <div className="col-1">:</div>
                                        <div>
                                          {row?.state ? row?.state : "-"}
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          lineHeight: "20px",
                                        }}
                                      >
                                        <div className="col-3 order-details-heading">
                                          PIN Code
                                        </div>
                                        <div className="col-1">:</div>
                                        <div>
                                          {row?.zipcode ? row?.zipcode : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {getDealerDetails(row)}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {loading ? (
                          <div className="page-loading">
                            <ReactLoading
                              type={"spin"}
                              color={"#E92E26"}
                              height={"50px"}
                              width={"50px"}
                            />
                          </div>
                        ) : (
                          <div className="page-loading">
                            <tr>
                              <td colSpan={6}>No records found</td>
                            </tr>
                          </div>
                        )}
                      </>
                    )}
                  </>
                  
                  {!loading && pageInfo?.totalPages > 1 ? (
                    <div className="pagination-styles">
                      <div>
                        Showing {(pageInfo.current_page - 1) * pageInfo.perPage + 1} to {pageInfo.current_page * pageInfo.perPage > pageInfo.total
                          ? pageInfo.total
                          : pageInfo.current_page * pageInfo.perPage} of {pageInfo.total} records
                      </div>
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={(e) => changePage(e.selected + 1)}
                        pageCount={pageInfo?.totalPages}
                        previousLabel="< previous"
                        renderOnZeroPageCount={null}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DealerList;
