import React, { useState, useEffect } from "react";
import { defaultPage } from "../../../Common/Constant";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Pagination from "../../Layout/Pagination";
import { useNavigate } from "react-router-dom";

import AddModal from "./AddModal";
import EditModal from "./EditModal";
import { roleDelete, rolesList } from "../../../api/accessManagemet";

function AccessManagementRole() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Access Management Role - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [uniqueStatus, setUniqueStatus] = useState();

  useEffect(() => {
    getList();
  }, [uniqueStatus]);

  const getList = async () => {
    let res = await rolesList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const deleteRow = async (id) => {
    let res = await roleDelete({ id: id });
    if (res && res.status === 1) {
      getList();
    }
  };

  const changePage = (pageInfo) => {
    setPageInfo(pageInfo);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function updateRole(id, newName) {
    const updatedList = lists.map((listItem) => {
      if (id == listItem.id) {
        return { ...listItem, name: newName };
      }
      return listItem;
    });
    setLists(updatedList);
  }

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Roles List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item">Roles</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
                  <div
                    className="col-2"
                    style={{ marginLeft: "10px", textAlign: "right" }}
                  >
                    <div className="form-group">
                      <button className="btn btn-primary" onClick={handleShow}>
                        <i className="ri-add-line"></i> Add Role
                      </button>
                      <AddModal
                        show={show}
                        handleClose={handleClose}
                        setUniqueStatus={setUniqueStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    {/* <!-- end card header --> */}
                    <div className="card-body">
                      <table className="table table-striped table-hover table_client">
                        <thead>
                          <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Sr. No.</th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Role Name
                            </th>
                            {/* <th scope="col" style={{textAlign:"left"}}>Module</th> */}
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {lists ? (
                            <>
                              {lists?.length > 0 ? (
                                <>
                                  {lists?.map((row, i) => {
                                    return (
                                      <tr key={row.ID}>
                                        <td>{i + 1}</td>
                                        <td style={{ textAlign: "left" }}>
                                          <strong>
                                            <span style={{ textAlign: "left" }}>
                                              {row?.name}
                                            </span>
                                          </strong>
                                        </td>
                                        {/* <td style={{textAlign:"left"}}>
                                                                   {row?.module}
                                                                </td> */}
                                        <td>
                                          <span
                                            className="p-2 font-16"
                                            title="Edit Role"
                                          >
                                            <EditModal
                                              id={row.ID}
                                              uniqueStatus={uniqueStatus}
                                              setUniqueStatus={setUniqueStatus}
                                              updateRole={updateRole}
                                            />
                                          </span>
                                          <span
                                            className="p-2 font-16"
                                            title="Delete Role"
                                          >
                                            <i
                                              className="ri-delete-bin-2-line del pointerC"
                                              onClick={() => deleteRow(row?.ID)}
                                            ></i>
                                          </span>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td colSpan={5}>No roles found</td>
                                  </tr>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <Pagination
                        pagination={pageInfo}
                        changePage={changePage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AccessManagementRole;
