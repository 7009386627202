import React, { useState, useEffect } from "react";
import { defaultPage } from "../../../Common/Constant";
import {
  productList,
  productDelete,
  productUpdateStatus,
  downloadBulkInventory,
  uploadProductList,
} from "../../../api/product";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Pagination from "../../Layout/Pagination";
import { useNavigate } from "react-router-dom";
import { BiDownload, BiUpload } from "react-icons/bi";
import Swal from "sweetalert2";




function ProductList() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Product List - Clarion Audio";
  });
  const [searchKeyword, setSearchKeyword] = useState();
  const [lists, setLists] = useState();
  const [pageInfo, setPagination] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getList();
  }, [searchKeyword]);

  const getList = async () => {
    let res = await productList({
      page: pageInfo.current_page,
      recPerPage: defaultPage.perPage,
      searchKeyword: searchKeyword,
    });
    if (res.status === 1) {
      setLists(res.payload);
      setPagination(res.paginationInfo);
    } else {
      setLists([]);
    }
  };

  const updateOrSetStatus = async (id, productStatus) => {
    let res = await productUpdateStatus({
      id: id,
      productStatus: productStatus === "active" ? 0 : 1,
    });
    if (res && res.status === 1) {
      getList();
    }
  };

  const deleteRow = async (id) => {
    let res = await productDelete({ id: id });
    if (res && res.status === 1) {
      getList();
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    getList();
  };

  const handleBulkDownload = async () => {
    await downloadBulkInventory();
  };

  const handleBulkUpload = async (e) => {
    const selectedFile= e.target.files[0];
    try {
      await uploadProductList(selectedFile).then((res) => {
        if (res.status === 1) {
          Swal.fire({
            text: "File uploaded successfully.",
            timer: 1500,
            icon: "success",
          });
          getList();
        } else {

          Swal.fire({
            text: "Failed to upload file.",
            timer: 1500,
            icon: "error",
          });
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        text: "Failed to upload file.",
        timer: 1500,
        icon: "error",
      });
    }
  }
  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">Product List</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a>Home</a>
                        </li>
                        <li className="breadcrumb-item">Product</li>
                        <li className="breadcrumb-item active">Product List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                <div className="col-xl-12 col-lg-12 d-flex mt-2 mb-2 justify-content-between">
                  <div style={{ display: "flex" }}>
                    <div
                      className="search-orderid"
                      style={{
                        background: "#ffffff",
                        marginBottom: "5px",
                        width: "300px",
                      }}
                    >
                      <input
                        type="search"
                        placeholder="Search by product name, sku code"
                        className="search-input"
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        style={{ width: "90%" }}
                      />
                      <img
                        src="/assets/images/search.png"
                        alt="search"
                        width={18}
                        height={18}
                        style={{ marginTop: "1px" }}
                      />
                    </div>
                  </div>
                  <div className="d-flex" style={{ gap: "10px" }}>
                    <div className="form-group">
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate("/product/add")}
                      >
                        <i className="ri-add-line"></i> Add Product
                      </button>
                    </div>
                    <div className="form-group">
                      <div className="dropdown">
                        <button
                          className="btn btn-dark dropdown-toggle btn-md"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                        >
                          Bulk Action
                        </button>
                        <ul
                          className="dropdown-menu mt-1"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <li style={{ display: "flex", alignItems: "center" }}>
                            <a
                              className="dropdown-item p-1"
                              style={{ cursor: "pointer" }}
                              onClick={handleBulkDownload}
                            >
                              <BiDownload color="#007bff" size="1.2em" /> Inventory Download
                            </a>
                          </li>
                          <li style={{ display: "flex", alignItems: "center" }}>
                            <input
                              type="file"
                              onChange={handleBulkUpload }
                              style={{ display: "none" }}
                              id="bulkUploadInput"
                            />
                            <label
                              className="dropdown-item p-1"
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#f3f3f9",
                                margin: 0,
                              }}
                              htmlFor="bulkUploadInput"
                            >
                              <BiUpload color="#28a745" size="1.2em" /> Inventory Upload
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end page title --> */}
              <div className="row mt-2">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    {/* <!-- end card header --> */}
                    <div className="card-body">
                      <table className="table table-striped table-hover table_client">
                        <thead>
                          <tr className="bg-primary" style={{ color: "white" }}>
                            <th scope="col">Sr. No.</th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Type
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Image
                            </th>
                            <th
                              scope="col"
                              style={{ textAlign: "left", width: "30%" }}
                            >
                              Title
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Brand
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Category
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              MRP
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Sell Price
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Product Status
                            </th>
                            <th scope="col" style={{ textAlign: "left" }}>
                              Published Status
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {lists ? (
                            <>
                              {lists?.length > 0 ? (
                                <>
                                  {lists?.map((row, i) => {
                                    return (
                                      <>
                                        <tr key={i}>
                                          <td>
                                            {(pageInfo.current_page - 1) *
                                              pageInfo.perPage +
                                              ++i}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.productType === "simple" ? (
                                              <>
                                                <span
                                                  className="text-primary"
                                                  style={{ fontWeight: "500" }}
                                                >
                                                  SIMPLE
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  className="text-info"
                                                  style={{ fontWeight: "500" }}
                                                >
                                                  {row?.productType.toUpperCase()}
                                                </span>
                                              </>
                                            )}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            <img
                                              src={
                                                row?.images &&
                                                row?.images.length > 0
                                                  ? row?.images[0]
                                                  : "/assets/images/no-image-available.png"
                                              }
                                              style={{ maxWidth: "80px" }}
                                              alt={row?.title}
                                            />
                                          </td>
                                          <td
                                            style={{
                                              textAlign: "left",
                                              width: "30%",
                                            }}
                                          >
                                            <strong>
                                              <span
                                                style={{ textAlign: "left" }}
                                              >
                                                {row?.title}
                                              </span>
                                            </strong>
                                            <br />
                                            <span
                                              className="text-info"
                                              style={{ fontWeight: "500" }}
                                            >
                                              SKU Code: {row?.skuCode}
                                            </span>
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.brandName}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.categoryName}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.mrp}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.finalPrice}
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            <div className="form-check form-switch">
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckDefault"
                                                checked={
                                                  row?.productStatus ===
                                                  "active"
                                                    ? "checked"
                                                    : ""
                                                }
                                                onChange={() =>
                                                  updateOrSetStatus(
                                                    row.ID,
                                                    row?.productStatus
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {row?.publishedOn &&
                                            row?.publishedOn > 0 ? (
                                              <>
                                                <span className="badge bg-success">
                                                  Published
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span className="badge bg-danger">
                                                  Unpublished
                                                </span>
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            <span
                                              className="p-2 font-18"
                                              title="Edit product"
                                            >
                                              <i
                                                className="ri-edit-2-line pointerC"
                                                onClick={() =>
                                                  navigate(
                                                    "/product/edit?id=" +
                                                      row.ID +
                                                      "&tab=generalInfo",
                                                    { state: row.ID }
                                                  )
                                                }
                                              ></i>
                                            </span>
                                            <span
                                              className="p-2 font-18"
                                              title="Delete product"
                                            >
                                              <i
                                                className="ri-delete-bin-2-line del pointerC"
                                                onClick={() =>
                                                  deleteRow(row?.ID)
                                                }
                                              ></i>
                                            </span>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td colSpan={10}>No product found</td>
                                  </tr>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                      <Pagination pageInfo={pageInfo} changePage={changePage} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ProductList;
