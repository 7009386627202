import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { defaultPage } from '../../../Common/Constant';
import { cartList } from '../../../api/cart';
import Sidebar from '../../Layout/Sidebar';
import Header from '../../Layout/Header';
import CartTab from './cart-tab';

const CartList = () => {
  const [pageInfo, setPageInfo] = useState({});
  const [cart, setCart] = useState([]);

  const fetchCartList = useCallback(async () => {
    let res = await cartList({ page: pageInfo.current_page, recPerPage: defaultPage.perPage });
    if (res.status === 1) {
      setCart(res.payload);
      setPageInfo(res.paginationInfo);
    } else {
      setCart([]);
    }
  }, [pageInfo.current_page]);

  useEffect(() => {
    fetchCartList();
  }, [fetchCartList]);

  return (
    <>
      <Sidebar />
      <Header />
      <div id='layout-wrapper'>
        <div className='main-content'>
          <div className='page-content'>
            <div className='container-fluid'>
              {/* sub header */}
              <div className='row'>
                <div className='col-12'>
                  <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                    <h4 className='mb-sm-0'> Cart List</h4>
                    <div className='page-title-right'>
                      <ol className='breadcrumb m-0'>
                        <li className='breadcrumb-item'>
                          <Link to='/'>Home</Link>
                        </li>
                        <li className='breadcrumb-item active'> Cart List</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              {/* List */}
              {cart.map((cartItem) => {
                return (
                  <div className='order-card card' key={cartItem.ID}>
                    <div className='card-body card-alignment'>
                      <div className='ordered-user-details'>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              fontWeight: '600',
                              lineHeight: '24px',
                            }}
                          >
                            <div className='col-2 order-details-heading'> Cart ID</div>
                            <div className='col-1'>:</div>
                            <div className='col-11'>
                              <b className='mx-1'> {cartItem.ID} </b> |{' '}
                              <span className='order-details-heading mx-1'>Updated On:</span>
                              &nbsp;
                              <span>{cartItem.updatedOn || '-'}</span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              fontWeight: '600',
                              lineHeight: '24px',
                            }}
                          >
                            <div className='col-2 order-details-heading'> Buyer</div>
                            <div className='col-1'>:</div>
                            <div className='col-12'>
                              {cartItem.buyerName} {cartItem.phone ? <span> - {cartItem.phone}</span> : ''}
                            </div>
                          </div>
                        </div>
                        <div>
                          <button className='ordered-items total-items'>
                            ₹ {cartItem.orderSubTotal} | {cartItem.items} Item
                          </button>
                        </div>
                      </div>
                      <CartTab id={cartItem.ID} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartList;
