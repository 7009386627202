import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Multiselect from 'multiselect-react-dropdown';
import { productDetails, productUpdate, productCategoryUpdate, productSKUUpdate, productContentUpdate, productSEOContentUpdate, getProductCategoryAttributes, getProductSKU, getProductContent, getProductSEOContent, relatedProductList, updateRelatedProduct, getSavedRelatedProduct } from '../../../api/product';

import { mappedCategoryAttributes } from '../../../api/category';
import { masterData } from '../../../api/master-data';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Swal from "sweetalert2";
// import { s3bucket } from '../../../Common/Constant';
import { uploadImageAPI } from '../../../services/api.services';
import { useNavigate, useLocation } from "react-router-dom";

function ProductEdit() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Edit Product - Clarion Audio";
    },);

    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [mappedAttributes, setMappedAttributes] = useState([]);
    const [brands, setBrands] = useState();
    const [tags, setTags] = useState();
    const [productContentType, setProductContentType] = useState();
    const [seoContent, setSeoContent] = useState();

    const location = useLocation();
    const queryString = new URLSearchParams(location.search)
    const id = queryString.get('id');
    const activeTab = queryString.get('tab');
    const [form, setForm] = useState({});
    const [catform, setCatForm] = useState({});
    const [skuform, setSkuForm] = useState({images:[]});
    const [contentform, setContentForm] = useState({});
    const [seoform, setSeoForm] = useState({});
    const [errors, setErrors] = useState({});

    const dragItem = useRef();
    const dragOverItem = useRef();

    const [relatedItems, setRelatedItems] = useState();
    const [relatedItmForm, setRelatedItmForm] = useState({});

    useEffect(() => {
        getCategories();
        getBrands();
        getTags();
        getProductContentType();
        getSeoContent();
    },[])

    const getCategories = async() => {
        let res = await masterData({typeSlug: 'category', pagePerRec: 500});
        if(res.status === 1){
            setCategories(res.payload);
        }
    }

    const getSubCategories = async(categoryID) => {
        setSubCategories([]);
        if(categoryID){
            let res = await masterData({typeSlug: 'category', parentID: categoryID, pagePerRec: 500});
            if(res.status === 1){
                setSubCategories(res.payload);
            }
        }
    }
    
    const getBrands = async() => {
        let res = await masterData({typeSlug: 'brand', pagePerRec: 500});
        if(res.status === 1){
            setBrands(res.payload);
        }
    }

    const getTags = async() => {
        let res = await masterData({typeSlug: 'tag', pagePerRec: 500});
        if(res.status === 1){
            setTags(res.payload);
        }
    }

    const getProductContentType = async() => {
        let res = await masterData({typeSlug: 'product-content', pagePerRec: 500});
        if(res.status === 1){
            setProductContentType(res.payload);
        }
    }
    
    const getSeoContent = async() => {
        let res = await masterData({typeSlug: 'seo-content', pagePerRec: 500});
        if(res.status === 1){
            setSeoContent(res.payload);
        }
    }

    const getRelatedProductList = async(categoryID, productID) =>{
        if(categoryID) {
            let res = await relatedProductList({payload: {categoryID: categoryID, productID: productID}});
            if(res.status === 1){
                setRelatedItems(res.payload);
            } else{
                setRelatedItems([]);
            }
        }
    }

    const getAttributesBasedOnCategory = async (categoryID) => {
        if(categoryID) {
            let res = await mappedCategoryAttributes({id: categoryID});
            if(res.status === 1){
                setMappedAttributes(res.payload);
            } else {
                setMappedAttributes([]);
            }
            getRelatedProductList(categoryID, id);
        }
    }

    const setField = (field, value) => {
        // setForm({
        //     ...form,
        //     [field]: value
        // });
        form[field] = value;
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const setCatField = (field, value) => {
        setCatForm({
            ...catform,
            [field]: value,
        });
        // catform[field] = value;/
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const setSkuField = (field, value) => {
        // setSkuForm({
        //     ...skuform,
        //     [field]: value
        // });
        skuform[field] = value;
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const setContentField = (field, value) => {
        // setContentForm({
        //     ...contentform,
        //     [field]: value
        // });
        contentform[field] = value;
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }

    const setSeoField = (field, value) => {
        // setSeoForm({
        //     ...seoform,
        //     [field]: value
        // });
        seoform[field] = value;
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });            
        }
    }
    
    const setRelatedItmField = (field, value) => {
        // setRelatedItmForm({
        //     ...relatedItmForm,
        //     [field]: value
        // });
        relatedItmForm[field] = value;
        if (!!errors[field]){
            setErrors({
                ...errors,
                [field]: null
            });
        }
    }

    // General info
    useEffect(() => {
        getProductDetails();
    },[]);
    const getProductDetails = async () => {
        let res = await productDetails({payload: id});
        if(res.status === 1){            
            setForm({
                ['productType']: res?.payload?.productType,
                ['productStatus']: res?.payload?.productStatus,
                ['title']: res?.payload?.title,
                ['description']: res?.payload?.description && res?.payload?.description !== "" && res?.payload?.description !== undefined ? res?.payload?.description.toString('html') : null,
                ['brandID']: res?.payload?.brandID,
                ['tags']: res?.payload?.tags ? res?.payload?.tags.split(',') : [],
                ['publishOnWebsite']: res?.payload?.publishedOn && res.payload.publishedOn > 0 ? true : false,
            });
        }
    }
    const validateProductUpdateForm = () =>{
        const {productType, title, brandID } = form;
        const newErrors = {};
        if(!productType || productType === 'Select product type') newErrors.productType = 'Please select product type';
        if(!title || title === '') newErrors.title = 'Please enter this field';
        if(!brandID || brandID === 'Select brand') newErrors.brandID = 'Please select brand';
        return newErrors;
    }
    const handleProductUpdate = async (e) => {
        e.preventDefault();
        setErrors();
        const formErrors = validateProductUpdateForm();
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        } else {
            let productTags = null;
            if(form?.tags?.length > 0){
                form?.tags?.map((tag, index) => {
                    if(index === 0){
                        productTags = tag.name;
                    } else {
                        productTags = productTags + ',' + tag.name;
                    }
                });
            }
            let res = await productUpdate({
                payload: {
                    productType: form?.productType,
                    productStatus: form?.productStatus,
                    title: form?.title,
                    description: form?.description && form?.description !== "" && form?.description !== undefined ? form?.description.toString('html') : null,
                    brandID: form?.brandID,
                    tags: productTags,
                    publishOnWebsite: form?.publishOnWebsite ? 1 : 0
                },
                id: id
            });
            if(res.status === 1){
                Swal.fire({
                    text:res.message,
                    timer: 1500,
                }).then(() => {
                    navigate("/product/edit?id="+id+"&tab=generalInfo");
                });
            }//  else {
            //     setErrors({...errors,['submit']:res.message})
            // }
        }
        
    }

    // Category info
    useEffect(() => {
        getPrdCategoryAttributes();
    },[]);
    const getPrdCategoryAttributes = async () => {
        let res = await getProductCategoryAttributes({payload: {id: id}});
        if(res.status === 1){
            let newcatForm = {
                ['categoryID']: res.payload[0].categoryID,
                ['subCategoryID']: res.payload[0].subCategoryID ? res.payload[0].subCategoryID : null,
            }
            getAttributesBasedOnCategory(res.payload[0].categoryID);
            getSubCategories(res.payload[0].categoryID);

            res.payload[0]?.attributeSet?.forEach((item) => {
                item?.child?.forEach((attrDet) => {
                newcatForm['attributeChck'+attrDet.ID]= true;
                newcatForm['attributeDetails-'+attrDet.ID]= attrDet.value;
                })
            });
            setCatForm(newcatForm);
        }
    };        
    const handleProductCategory = async (e) => {
        // console.log('cat--form',Object.keys(catform).slice(2),Object.values(catform).slice(2),Object.entries(catform).slice(2))
        const selectedIds = Object.entries(catform).slice(2).map(item=> {
            if(item[0]?.includes('attributeChck') && item[1]) {
                return {
                    ID:Number(item[0]?.split('attributeChck')[1]),
                   }
            }
            else if(item[0]?.includes('attributeDetails')&& item[1]?.length){
                return {
                    ID: Number(item[0]?.split('attributeDetails-')[1]),
                    value:item[1]
                   }
            }
        })
        const reducedArray = selectedIds.reduce((obj, item) => {
            obj[item?.ID] ? obj[item?.ID] = { ...item } : (obj[item?.ID] = { ...item });
            return obj;
        }, []);

        let res = await productCategoryUpdate({
            payload: {
                categoryID: catform?.categoryID,
                subCategoryID: catform?.subCategoryID ? catform?.subCategoryID : null,
                attributeDetails:reducedArray.filter(function (el) {
                    return el != null;
                  }),
            },
            id: id
        });
        if(res.status === 1){
            Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                navigate("/product/edit?id="+id+"&tab=categoryInfo");
            });
        }
    };
    
    // SKU details
    useEffect(() => {
        getPrdSKU();
    },[]);
    const getPrdSKU = async () => {
        let res = await getProductSKU({payload: {id: id}});
        if(res.status === 1){
            await res?.payload?.map((sk) => {
                //  setSkuForm({
                //     ['skuCode']: sk?.skuCode,
                //     ['skuName']: sk?.skuName,
                //     ['mrp']: sk?.mrp,
                //     ['sellPrice']: sk?.finalPrice,
                //     ['stock']: sk?.totalStock,
                //     ['images']: sk?.images
                // });
                skuform['skuCode'] = sk?.skuCode;
                skuform['skuName'] = sk?.skuName;
                skuform['mrp'] = sk?.mrp;
                skuform['sellPrice'] = sk?.finalPrice;
                skuform['stock'] = sk?.totalStock;
                skuform['gst'] = sk?.gst;
                skuform['images'] = sk?.images;
            });
        }
    };
    const handleProductSKU = async (e) => {
        let res = await productSKUUpdate({
            payload: {
                skus: [
                    {
                        skuCode: skuform?.skuCode,
                        skuName: skuform?.skuName,
                        mrp: skuform?.mrp ? skuform?.mrp : 0,
                        sellPrice: skuform?.sellPrice,
                        gst: skuform?.gst,
                        stock: skuform?.stock ? skuform?.stock : 0,
                        images: skuform?.images ? skuform?.images : []
                    }
                ]
            },
            id: id
        });
        if(res.status === 1){
            Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                navigate("/product/edit?id="+id+"&tab=skuDetails");
            });
        }
    }

    // Product content
    useEffect(() => {
        getPrdContent();
    },[productContentType]);
    const getPrdContent = async () => {
        let res = await getProductContent({payload: {id: id}});
        if(res.status === 1){
            let contentFormObj = {};
            productContentType?.map((prcnt) => {
                let prdCnt = res.payload?.find((cnt) => cnt.contentTypeID === prcnt.ID);
                if(prdCnt){
                    contentFormObj['productContent-'+prcnt.ID] = prdCnt.content && prdCnt.content !== "" && prdCnt.content !== undefined ? prdCnt.content.toString('html') : "";
                }
            });
            setContentForm(contentFormObj);
        }
    };
    const handleProductContent = async (e) => {
        let res = await productContentUpdate({
            payload: {
                contentDetails: productContentType.map((content) => {
                    console.log(contentform['productContent-'+content.ID]);
                    return {
                        ID: content.ID,
                        content: contentform['productContent-'+content.ID] !== "" && contentform['productContent-'+content.ID] !== undefined ? contentform['productContent-'+content.ID].toString('html') : ""
                    }
                })
            },
            id: id
        });
        if(res.status === 1){
            Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                navigate("/product/edit?id="+id+"&tab=productContent");
            });
        }
    }

    // Product SEO content
    useEffect(() => {
        getPrdSEOContent();
    },[seoContent]);
    const getPrdSEOContent = async () => {
        let res = await getProductSEOContent({payload: {id: id}});
        if(res.status === 1){
            let seoFormObj = {};
            seoContent?.map((seoCnt) => {
                let prdCnt = res.payload?.find((cnt) => cnt.contentTypeID === seoCnt.ID);
                if(prdCnt){
                    seoFormObj['seoContent-'+seoCnt.ID] = prdCnt.content && prdCnt.content !== "" && prdCnt.content !== undefined ? prdCnt.content.toString('html') : "";
                }
            });
            setSeoForm(seoFormObj);
        }
    };
    const handleProductSEOContent = async (e) => {
        let res = await productSEOContentUpdate({
            payload: {
                contentDetails: seoContent.map((content) => {
                    return {
                        ID: content.ID,
                        content: seoform['seoContent-'+content.ID] !== "" && seoform['seoContent-'+content.ID] !== undefined ? seoform['seoContent-'+content.ID].toString('html') : ""
                    }
                })
            },
            id: id
        });
        if(res.status === 1){
            Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                navigate("/product/edit?id="+id+"&tab=seoDetails");
            });
        }
    }

    // Related items
    const handleRelatedItems = async (e) => {
        let res = await updateRelatedProduct({
            payload: {
                relatedProductIDArr: relatedItmForm['relatedProductIDArr'].map((item) => {
                    return item.ID;
                }),
                productID: id
            }
        });
        if(res.status === 1){
            navigate("/product/edit?id="+id+"&tab=relatedItems");
        }
    }
    useEffect(() => {
            getSavedRelatedProductItems();
    },[relatedItems]);
    const getSavedRelatedProductItems = async () => {
        let res = await getSavedRelatedProduct({payload: {productID: id}});
        if(res.status === 1){
            relatedItmForm['relatedProductIDArr'] = res.payload.map((item) => {
                return item.ID;
            });
        }
    };
    
    const handleCancel = () => {
        navigate("/product/list");
    }


    const handleChange = (e) => {
            checkFile(e.target.files, e.target.id.split('-')[1]);
    };
    const checkFile = async (files, id) => {
        if(files?.length === 0) return;

        // let fileElement = files[0];
        // let fileExtension = "";
        // if (fileElement.name.lastIndexOf(".") > 0) {
        //     fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        // }
        let res = await uploadImageAPI('/upload/', files)
        if (res?.status === 1) {
                let imageUploadedUrls = res.payload.url;
                  await imageUploadedUrls?.map(imageUploadedUrl => {
                        // skuform['images'].push(imageUploadedUrl);
                skuform['images'] = [...skuform['images'], imageUploadedUrl];
            });

                setSkuForm({
                    ...skuform,
                    ['images']: skuform['images']
                });
        }
    }

    const removeImage = (index) => {
        skuform['images'].splice(index, 1);
        setSkuForm({
            ...skuform,
            ['images']: skuform['images']
        });
    }

    const dragStart = (e, position) => {
        dragItem.current = position;
    };
    
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };
     
    const drop = (e) => {
        const copyListItems = [...skuform?.images];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        // setList(copyListItems);
        setSkuForm({
            ...skuform,
            ['images']: copyListItems
        });
    };

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Edit Product</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a>Home</a></li>
                                                <li className="breadcrumb-item">Product</li>
                                                <li className="breadcrumb-item active">Edit Product</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body p-5">
                                            <div className="row gx-5">
                                                <aside className="col-lg-3 border-end">
                                                    <div className="d-flex mb-4">
                                                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                            <button className={"nav-link text-start p-3 "+(activeTab === 'generalInfo' ? 'active' : '')} id="v-pills-generalInfo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-generalInfo" type="button" role="tab" aria-controls="v-pills-generalInfo" aria-selected="true" title="Product General Informations" onClick={()=>navigate("/product/edit?id="+id+"&tab=generalInfo")}>General</button>

                                                            <button className={"nav-link text-start p-3 "+(activeTab === 'categoryInfo' ? 'active' : '')} id="v-pills-categoryInfo-tab" data-bs-toggle="pill" data-bs-target="#v-pills-categoryInfo" type="button" role="tab" aria-controls="v-pills-categoryInfo" aria-selected="false" title="Product category information" onClick={()=>navigate("/product/edit?id="+id+"&tab=categoryInfo")}>Category Info</button>

                                                            <button className={"nav-link text-start p-3 "+(activeTab === 'skuDetails' ? 'active' : '')} id="v-pills-skuDetails-tab" data-bs-toggle="pill" data-bs-target="#v-pills-skuDetails" type="button" role="tab" aria-controls="v-pills-skuDetails" aria-selected="false" title="Product SKU details" onClick={()=>navigate("/product/edit?id="+id+"&tab=skuDetails")}>
                                                                SKU
                                                                <br />
                                                                <small>(Pricing, Images and Inventory)</small>
                                                            </button>

                                                            <button className={"nav-link text-start p-3 "+(activeTab === 'productContent' ? 'active' : '')} id="v-pills-productContent-tab" data-bs-toggle="pill" data-bs-target="#v-pills-productContent" type="button" role="tab" aria-controls="v-pills-productContent" aria-selected="false" title="Product content details" onClick={()=>navigate("/product/edit?id="+id+"&tab=productContent")}>Product Content</button>

                                                            <button className={"nav-link text-start p-3 "+(activeTab === 'seoDetails' ? 'active' : '')} id="v-pills-seoDetails-tab" data-bs-toggle="pill" data-bs-target="#v-pills-seoDetails" type="button" role="tab" aria-controls="v-pills-seoDetails" aria-selected="false" title="Product SEO details" onClick={()=>navigate("/product/edit?id="+id+"&tab=seoDetails")}>SEO Details</button>

                                                            <button className={"nav-link text-start p-3 "+(activeTab === 'relatedItems' ? 'active' : '')} id="v-pills-relatedItems-tab" data-bs-toggle="pill" data-bs-target="#v-pills-relatedItems" type="button" role="tab" aria-controls="v-pills-relatedItems" aria-selected="false" title="Related items" onClick={()=>navigate("/product/edit?id="+id+"&tab=relatedItems")}>Related Items</button>
                                                        </div>
                                                    </div>
                                                </aside>
                                                <div className="col-lg-9">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                        {/* Product general info */}
                                                        <div className={"tab-pane fade content-body p-xl-4 "+(activeTab === 'generalInfo' ? 'show active' : '')} id="v-pills-generalInfo" role="tabpanel" aria-labelledby="v-pills-generalInfo-tab">
                                                            <div className="row gx-2">
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="productType" className="form-label">Product type <span className="required_field">*</span></label>
                                                                        <select id="productType" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setField('productType',e.target.value)} defaultValue={form?.productType}>
                                                                            <option value={""} key={0}>Select product type</option>
                                                                            <option value="simple" selected={form?.productType === 'simple' ? true : false} key={1}>Simple</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="productStatus" className="form-label">Product status <span className="required_field">*</span></label>
                                                                        <select className="form-select" id="productStatus" onChange={(e) => setField('productStatus', e.target.value)} defaultValue={form?.productStatus}>
                                                                            <option value="inactive" selected={form?.productStatus === 'inactive' ? true : false} key={0}>Inactive</option>
                                                                            <option value="active" selected={form?.productStatus === 'active' ? true : false} key={1}>Active</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label htmlFor="title" className="form-label">Product name <span className="required_field">*</span></label>
                                                                <input type="text" placeholder="Enter product name" className="form-control" id="title" defaultValue={form?.title} onChange={(e) => setField('title', e.target.value)} />
                                                            </div>

                                                            <div className="mb-4">
                                                                <label htmlFor="description" className="form-label">Description</label>
                                                                {/* <textarea className="form-control" id="description" rows="4" defaultValue={form?.description} onChange={(e) => setField('description', e.target.value)}></textarea> */}
                                                                <ReactQuill theme="snow" id="description" value={form?.description} onChange={(e) => setField('description', e)} />
                                                            </div>
                                                            
                                                            <div className="row gx-2">
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="brandID" className="form-label">Brand <span className="required_field">*</span></label>
                                                                        <select className="form-select" id="brandID" defaultValue={form?.brandID} onChange={(e) => setField('brandID', e.target.value)}>
                                                                            <option value="" key={0}>Select brand</option>
                                                                            {brands?.map((brand, index) => (
                                                                                <option value={brand.ID} key={index+1} selected={form?.brandID === brand.ID ? true : false} >{brand.name}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="productTaxID" className="form-label">Tax ID</label>
                                                                        <input type="text" placeholder="Enter Tax ID" className="form-control" id="productTaxID" defaultValue={form?.productTaxID} onChange={(e) => setField('productTaxID', e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label htmlFor="tags" className="form-label">Tags</label>
                                                                {/* <input type="text" placeholder="Enter tags (comma separated)" className="form-control" id="tags" defaultValue={form?.tags} onChange={(e) => setField('tags', e.target.value)} /> */}
                                                                <Multiselect
                                                                    options={tags} // Options to display in the dropdown
                                                                    selectedValues={tags?.filter((tag) => form?.tags?.includes(tag.name))} // Preselected value to persist in dropdown
                                                                    onSelect={(e) => setField('tags', e)} // Function will trigger on select event
                                                                    onRemove={(e) => setField('tags', e)} // Function will trigger on remove event
                                                                    displayValue="name" // Property name to display in the dropdown options
                                                                />                                                                
                                                            </div>

                                                            <label className="form-check mb-4 form-switch">
                                                                <input className="form-check-input" type="checkbox" role="switch" id="publishOnWebsite" defaultChecked={form?.publishOnWebsite} onChange={(e) => setField('publishOnWebsite', e.target.checked)} />
                                                                <span className="form-check-label">
                                                                    Publish this product on website
                                                                </span>
                                                            </label>

                                                            <div className="col-md-12 text-end" id="prdUpdateBtnSection">
                                                                <button type="button" className="btn btn-primary mx-2" id="submitButton" onClick={handleProductUpdate} key={"btnSubmitGeneralInfo"}>Update product</button>
                                                                <button type="button" className="btn btn-danger mx-2" id="cancelButton" onClick={handleCancel} key={"btnCancelGeneralInfo"}>Cancel</button>
                                                            </div>
                                                        </div>

                                                        {/* Product category info */}
                                                        <div className={"tab-pane fade content-body p-xl-4 "+(activeTab === 'categoryInfo' ? 'show active' : '')} id="v-pills-categoryInfo" role="tabpanel" aria-labelledby="v-pills-categoryInfo-tab">
                                                            {/* Render mapped attribute and category and select which ever is selected in productMappedAttributes */}

                                                            <div className="mb-4">
                                                                <label htmlFor="categoryID" className="form-label">Category <span className="required_field">*</span></label>
                                                                <select className="form-select" id="categoryID" onChange={(e) => {setCatField('categoryID', e.target.value); getAttributesBasedOnCategory(e.target.value); getSubCategories(e.target.value)}} defaultValue={catform?.categoryID}>
                                                                    <option value="" key={0}>Select category</option>
                                                                    {categories?.map((ctg, index) => (
                                                                        <option value={ctg.ID} key={index+1} selected={catform?.categoryID === ctg.ID ? true : false} >{ctg.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="mb-4">
                                                                <label htmlFor="subCategoryID" className="form-label">Sub category</label>
                                                                <select className="form-select" id="subCategoryID" onChange={(e) => setCatField('subCategoryID', e.target.value)} defaultValue={catform?.subCategoryID}>
                                                                    <option value="" key={0}>Select sub category</option>
                                                                    {subCategories?.map((ctg, index) => (
                                                                        <option value={ctg.ID} key={index+1} selected={catform?.subCategoryID === ctg.ID ? true : false} >{ctg.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            {mappedAttributes?.map((attr, indx) => (
                                                                <div className="mb-4">
                                                                    <label htmlFor={"categoryAttributes-"+attr.ID} className="form-label">{attr.name}</label>
                                                                    {attr?.child?.map((attrChildVal, chInd) => (
                                                                        <div className="row gx-2 d-flex align-items-center mb-3">
                                                                            <div className="col-md-5" key={chInd}>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" id={"attributeChck"+attrChildVal.ID} onChange={(e) => setCatField('attributeChck'+attrChildVal.ID,e.target.checked)} value={attrChildVal.ID} defaultChecked={catform['attributeChck'+attrChildVal.ID]} />
                                                                                    <label className="form-check-label" htmlFor={"attributeChck"+attrChildVal.ID}>
                                                                                     {attrChildVal.name}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-7">
                                                                                <input type="text" placeholder="Enter details" className="form-control" id={"attributeDetails-"+attrChildVal.ID} onChange={(e) => setCatField('attributeDetails-'+attrChildVal.ID, e.target.value)} defaultValue={catform['attributeDetails-'+attrChildVal.ID]} />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}

                                                            <div className="col-md-12 text-end" id="prdCtgUpdateBtnSection">
                                                                <button type="button" className="btn btn-primary mx-2" id="submitButtonCategory" onClick={handleProductCategory} key={"btnSubmitCategoryInfo"}>Update category</button>
                                                                <button type="button" className="btn btn-danger mx-2" id="cancelButtonCategory" onClick={handleCancel} key={"btnCancelCategoryInfo"}>Cancel</button>
                                                            </div>
                                                        </div>

                                                        {/* Product SKU details */}
                                                        <div className={"tab-pane fade content-body p-xl-4 "+(activeTab === 'skuDetails' ? 'show active' : '')} id="v-pills-skuDetails" role="tabpanel" aria-labelledby="v-pills-skuDetails-tab">
                                                            {/* SKU Content */}
                                                            <div className="row gx-2">
                                                                <div className="col-sm-5">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="skuCode" className="form-label">SKU Code<span className="required_field">*</span></label>
                                                                        <input type="text" placeholder="Enter SKU Code" className="form-control" id="skuCode" defaultValue={skuform?.skuCode} onChange={(e) => setSkuField('skuCode', e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-7">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="skuName" className="form-label">SKU Name<span className="required_field">*</span></label>
                                                                        <input type="text" placeholder="Enter SKU Name" className="form-control" id="skuName" defaultValue={skuform?.skuName} onChange={(e) => setSkuField('skuName', e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* SKU pricing */}
                                                            <div className="row gx-2">
                                                                <div className="col-sm-4">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="mrp" className="form-label">MRP<span className="required_field">*</span></label>
                                                                        <input type="number" placeholder="Enter MRP" className="form-control" id="mrp" defaultValue={skuform?.mrp} onChange={(e) => setSkuField('mrp', e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="sellPrice" className="form-label">Sell Price<span className="required_field">*</span></label>
                                                                        <input type="number" placeholder="Enter Sell Price" className="form-control" id="sellPrice" defaultValue={skuform?.sellPrice} onChange={(e) => setSkuField('sellPrice', e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="mb-4">
                                                                        <label htmlFor="stock" className="form-label">Stock<span className="required_field">*</span></label>
                                                                        <input type="text" placeholder="Enter Stock" className="form-control" id="stock" defaultValue={skuform?.stock} onChange={(e) => setSkuField('stock', e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* SKU Images */}
                                                            <div className="row gx-2">
                                                                <div className="col-sm-6">
                                                                    <div className="mb-4">
                                                                        <label className="form-label">
                                                                            Image <small>(Max file size is 5MB)</small>
                                                                        </label>
                                                                        <input type="file" className="form-control" id="skuImageFile-0" onChange={handleChange} multiple />

                                                                        <div className="row gx-2 mt-2 d-flex align-items-center justify-content-start" id="skuImagePreview-0" >
                                                                            {skuform?.images?.map((img, imgIndx) => (                                                                                
                                                                                <div className="col-md-3 mt-3" key={imgIndx} id={"skuImg-"+imgIndx} 
                                                                                onDragStart={(e) => dragStart(e, imgIndx)}
                                                                                        onDragEnter={(e) => dragEnter(e, imgIndx)}
                                                                                        onDragEnd={drop}
                                                                                        draggable >                                                                                    
                                                                                    <div className="card mb-2 ms-2" style={{maxWidth: "100px"}}>
                                                                                        <img src={img} alt={skuform?.skuName} />

                                                                                        <button type="button" className="btn btn-danger btn-sm" style={{position: "absolute", top: "-10px", right: "-10px"}} 
                                                                                        onClick={() => {
                                                                                            removeImage(imgIndx);
                                                                                        }} >
                                                                                            x
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <label htmlFor="gst" className="form-label">GST<span className="required_field">*</span></label>
                                                                    <input type="text" placeholder="Enter GST" className="form-control" id="gst" defaultValue={skuform?.gst} onChange={(e) => setSkuField('gst', e.target.value)} />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12 text-end" id="prdSKUUpdateBtnSection">
                                                                <button type="button" className="btn btn-primary mx-2" id="submitButtonSKU" onClick={handleProductSKU} key={"btnSubmitSKUInfo"}>Update SKU</button>
                                                                <button type="button" className="btn btn-danger mx-2" id="cancelButtonSKU" onClick={handleCancel} key={"btnCancelSKUInfo"}>Cancel</button>
                                                            </div>
                                                        </div>

                                                        {/* Product content */}
                                                        <div className={"tab-pane fade content-body p-xl-4 "+(activeTab === 'productContent' ? 'show active' : '')} id="v-pills-productContent" role="tabpanel" aria-labelledby="v-pills-productContent-tab">
                                                            {productContentType?.map((prcnt) => (
                                                                <div className="mb-4">
                                                                    <label htmlFor={"productContent-"+prcnt.ID} className="form-label">{prcnt.name}</label>
                                                                    {/* <textarea className="form-control" id={"productContent-"+prcnt.ID} rows="4" onChange={(e) => setContentField("productContent-"+prcnt.ID, e.target.value)} defaultValue={contentform['productContent-'+prcnt.ID]}></textarea> */}

                                                                    <ReactQuill theme="snow" id={"productContent-"+prcnt.ID} value={contentform['productContent-'+prcnt.ID]} onChange={(e) => setContentField("productContent-"+prcnt.ID, e)} />
                                                                </div>
                                                            ))}
                                                            <div className="col-md-12 text-end" id="prdContentUpdateBtnSection">
                                                                <button type="button" className="btn btn-primary mx-2" id="submitButtonContent" onClick={handleProductContent} key={"btnSubmitProductContent"}>Update product content</button>
                                                                <button type="button" className="btn btn-danger mx-2" id="cancelButtonContent" onClick={handleCancel} key={"btnCancelProductContent"}>Cancel</button>
                                                            </div>
                                                        </div>

                                                        {/* Product SEO details */}
                                                        <div className={"tab-pane fade content-body p-xl-4 "+(activeTab === 'seoDetails' ? 'show active' : '')} id="v-pills-seoDetails" role="tabpanel" aria-labelledby="v-pills-seoDetails-tab">
                                                            {seoContent?.map((seoCnt) => 
                                                                (seoCnt.slug === 'meta-title' || seoCnt.slug === 'meta-keywords') ? (
                                                                    <div className="mb-4">
                                                                        <label htmlFor={"seoContent-"+seoCnt.ID} className="form-label">{seoCnt.name}</label>
                                                                        <input type="text" placeholder={"Enter "+seoCnt.name} className="form-control" id={"seoContent-"+seoCnt.ID} onChange={(e) => setSeoField("seoContent-"+seoCnt.ID, e.target.value)} defaultValue={seoform['seoContent-'+seoCnt.ID]} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="mb-4">
                                                                        <label htmlFor={"seoContent-"+seoCnt.ID} className="form-label">{seoCnt.name}</label>
                                                                        <textarea className="form-control" id={"seoContent-"+seoCnt.ID} rows="4" onChange={(e) => setSeoField("seoContent-"+seoCnt.ID, e.target.value)} defaultValue={seoform['seoContent-'+seoCnt.ID]}></textarea>
                                                                    </div>
                                                                )
                                                            )}

                                                            <div className="col-md-12 text-end" id="prdSEOContentUpdateBtnSection">
                                                                <button type="button" className="btn btn-primary mx-2" id="submitButtonSEOContent" onClick={handleProductSEOContent} key={"btnSubmitSEOContent"}>Update SEO content</button>
                                                                <button type="button" className="btn btn-danger mx-2" id="cancelButtonSEOContent" onClick={handleCancel} key={"btnCancelSEOContent"}>Cancel</button>
                                                            </div>
                                                        </div>

                                                        {/* Related items */}
                                                        <div className={"tab-pane fade content-body p-xl-4 "+(activeTab === 'relatedItems' ? 'show active' : '')} id="v-pills-relatedItems" role="tabpanel" aria-labelledby="v-pills-relatedItems-tab">
                                                            <div className="mb-4">
                                                                {relatedItems ? (
                                                                    <>
                                                                        <div className="row gx-2 mb-5">
                                                                            <label htmlFor="relatedItems" className="form-label">Related items</label>
                                                                            <Multiselect
                                                                                options={relatedItems} // Options to display in the dropdown
                                                                                selectedValues={relatedItems?.filter((item) => relatedItmForm?.relatedProductIDArr?.includes(item.ID))} // Preselected value to persist in dropdown
                                                                                onSelect={(e) => setRelatedItmField('relatedProductIDArr', e)} // Function will trigger on select event
                                                                                onRemove={(e) => setRelatedItmField('relatedProductIDArr', e)} // Function will trigger on remove event
                                                                                displayValue="title" // Property name to display in the dropdown options
                                                                            />
                                                                        </div>

                                                                        <div className="col-md-12 text-end" id="prdRelatedItemsUpdateBtnSection">
                                                                            <button type="button" className="btn btn-primary mx-2" id="submitButtonRelatedItems" onClick={handleRelatedItems} key={"btnSubmitRelatedItems"}>Update related items</button>
                                                                            <button type="button" className="btn btn-danger mx-2" id="cancelButtonRelatedItems" onClick={handleCancel} key={"btnCancelRelatedItems"}>Cancel</button>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <p>No related items found</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ProductEdit