import { useState } from "react";
import { Modal } from "react-bootstrap";
import { addNewRole } from "../../../api/accessManagemet";
import Swal from "sweetalert2";

function AddModal({ show, handleClose, setUniqueStatus }) {
  const [roleDetails, setRoleDetails] = useState();

  const addRoleDetails = async (roleDetails) => {
    let res = await addNewRole({
      payload: {
        name: roleDetails?.name,
        status: 1,
        priority: 0,
        parentID: 0,
      },
    });
    if (res.status === 1) {
      setUniqueStatus({ status: res.status, message: res.message });
      Swal.fire({
        text: res.message,
        timer: 1500,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addRoleDetails(roleDetails);
          }}
          id="editmodal"
          className="w-full max-w-sm"
        >
          <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading"
                for="name"
              >
                Role Name
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                className="bg-gray-200 input-styles border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="name"
                placeholder="Add a Role"
                type="text"
                value={roleDetails?.name}
                onChange={(e) => {
                  setRoleDetails({ ...roleDetails, name: e.target.value });
                }}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="modal-add-button"
          onClick={handleClose}
          form="editmodal"
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddModal;
