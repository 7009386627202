import { get, postAPI } from "../services/api.services";
const { defaultPage } = require('../Common/Constant');

export const subscriberList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/user/subscriber/list', data)
    return res;
}

export const contactList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/user/contact/list', data)
    return res;
}

export const customerList = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword
    }
    let res = await postAPI('/user/customer/list', data)
    return res;
}

export const storeLocator = async({page=defaultPage.current_page, recPerPage=defaultPage.perPage, searchKeyword = ""}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
    }
    let res = await get('/crm/store-locator/request/list/',data);
    return res;
}