import { useState } from "react";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { addNewUser } from "../../../api/accessManagemet";

const rolesData = [
  {
    id: 0,
    name: "Super Admin",
    isChecked: false,
  },
  {
    id: 1,
    name: "Admin",
    isChecked: false,
  },
  {
    id: 2,
    name: "Customer Support",
    isChecked: false,
  },
];
function AddModal({ show, handleClose, name, setUniqueStatus }) {
  const [userDetails, setUserDetails] = useState();
  const [rolesDetails, setRolesDetails] = useState(rolesData);

  const newUser = async (userDetails) => {
    let res = await addNewUser({
      payload: {
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        email: userDetails?.email,
        phone: userDetails?.mobile,
        password: "test@123",
      },
    });
    if (res.status === 1) {
      setUniqueStatus({ status: res.status, message: res.message });
      Swal.fire({
        text: res.message,
        timer: 1500,
      });
    }
  };

  const getInputField = (text, targetName, placeholderText) => {
    return (
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/3">
          <label className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading">
            {text}
          </label>
        </div>
        <div className="md:w-2/3">
          <input
            className="bg-gray-200 input-styles border-2 border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
            placeholder={placeholderText}
            name={targetName}
            type={text=== 'Mobile' ? 'number' : "text"}
            onChange={(e) => {
              setUserDetails({
                ...userDetails,
                [e.target.name]: e.target.value,
              });
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleClose} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            newUser(userDetails);
          }}
          id="editmodal"
          className="w-full max-w-sm"
        >
          <div className="pb-3 d-flex">
            {getInputField("First Name", "firstName", "Add First Name")}
            <div style={{ marginLeft: "20px" }}>
              {getInputField("Last Name", "lastName", "Add Last Name")}
            </div>
          </div>
          <div className="pb-3 d-flex">
            {getInputField("Email", "email", "Add Email")}{" "}
            <div style={{ marginLeft: "20px" }}>
              {getInputField("Mobile", "mobile", "Add Mobile Number")}
            </div>
          </div>
          {/* <div className="md:flex md:items-center mb-6">
            <div className="md:w-1/3">
              <label
                className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4 input-heading"
              >
                Role
              </label>
            </div>
            <div className="d-flex flex-wrap">
              {rolesDetails?.length
                ? rolesDetails?.map((row, i) => {
                    return (
                      <div className="d-flex w-50">
                        <input
                          className="swal2-checkbox"
                          type="checkbox"
                          style={{ width: "16px", marginRight: "5px" }}
                          onChange={(e) => {
                            setRolesDetails(
                              rolesDetails?.map((item) => {
                                if (item.id === row.id) {
                                  item.isChecked = e.target.checked;
                                }
                                return item;
                              })
                            );
                          }}
                        />
                        <span>{row?.name ? row?.name : "-"}</span>
                      </div>
                    );
                  })
                : ""}{" "}
            </div>
          </div> */}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="modal-add-button"
          onClick={handleClose}
          form="editmodal"
        >
          Add
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddModal;
