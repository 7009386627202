import React, { useState, useEffect } from 'react';
import { defaultPage } from '../../../Common/Constant';
import { masterData } from '../../../api/master-data';
import { attributeList, attributeDelete, attributeUpdateStatus } from '../../../api/attribute';
import Footer from '../../Layout/Footer';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import Pagination from '../../Layout/Pagination';
import { useNavigate } from "react-router-dom";

function AttributeList() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Attribute List - Clarion Audio";
    },);
    const [parentAttributeID, setParentAttributeID] = useState();
    const [parentAttribute, setParentAttribute] = useState();
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPageInfo] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getParentAttribute();
    },[])

    const getParentAttribute = async() => {
        let res = await masterData({typeSlug: 'attribute-set'});
        if(res.status === 1){
            setParentAttribute(res.payload)

            // let parentID = res.payload[0].ID;
            // setParentAttributeID(parentID);
            getList();
        }
    }

    useEffect(() => {
        getList();
    },[parentAttributeID]);

    const getList = async() =>{
        if(parentAttributeID){
            let res = await attributeList({ page: pageInfo.current_page, recPerPage: defaultPage.perPage, searchKeyword: searchKeyword, parentID: parentAttributeID });
            if(res.status === 1){
                setLists(res.payload)
                setPageInfo(res.paginationInfo)
            } else{
                setLists([])
                document.getElementById("noRecordMsg").innerHTML = "No attribute found";
            }
        } else {
            setLists([])
        }
    }

    const updateOrSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await attributeUpdateStatus({id:id,status:status});
        if(res && res.status === 1){
            getList();            
        }
    }

    const deleteRow = async (id) => {
        let res = await attributeDelete({id:id})
        if(res && res.status === 1){
            getList()
        }
    }

    const changePage = (p) => {
        pageInfo.current_page = p;
        getList();
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Attribute List</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item">Attribute</li>
                                                <li className="breadcrumb-item active">Attribute List</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5 page-title-box d-sm-flex align-items-center justify-content-between">
                                <div className="col-xl-12 col-lg-12 d-flex justify-content-end mt-2 mb-2">
                                    <div className="col-2" style={{marginLeft: "10px", textAlign: "right"}}>
                                        <div className="form-group">
                                            <button className="btn btn-primary" onClick={() => navigate('/attribute/add')}> <i className="ri-add-line"></i> Add Attribute</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="col-md-9"></div>
                                                <div className="col-md-3">
                                                    <div className="mb-3">
                                                        <select className="form-select" data-choices data-choices-sorting="true" id="parentAttributeID" onChange={(e) => setParentAttributeID(e.target.value)}>
                                                            <option value={""} disabled selected key={0}>Select attribute set</option>
                                                            {parentAttribute?.map((row) => {
                                                                return (<option value={row.ID} key={row.ID} selected={row.ID === parentAttributeID ? true : false}>{row.name}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Name</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Priority</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, i) => {
                                                            return(<>
                                                            <tr>
                                                                <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++i}</td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <strong><span style={{textAlign:"left"}}>{row?.name}</span></strong>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    {row?.priority ? row?.priority : "NA"}
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <div className="form-check form-switch">
                                                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onChange={()=>updateOrSetStatus(row.ID, row.status)} />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span className="p-2 font-16" title="Edit attribute"> 
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => navigate('/attribute/edit?id=' + row.ID, { state: row.ID })}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete attribute">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.ID)}></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={5} id="noRecordMsg">Please select attribute set from top to view attributes</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AttributeList