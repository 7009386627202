import Swal from "sweetalert2";
import { deleteAPI, get, postAPI, putAPI } from "../services/api.services";
const { defaultPage } = require("../Common/Constant");

export const rolesList = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  searchKeyword = "",
}) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    q: searchKeyword,
  };
  let res = await get("/role/list/", data);
  return res;
};

export const modulesList = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  searchKeyword = "",
}) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    q: searchKeyword,
  };
  let res = await get("/module/list/", data);
  return res;
};

export const usersList = async ({
  page = defaultPage.current_page,
  recPerPage = defaultPage.perPage,
  searchKeyword = "",
}) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    q: searchKeyword,
  };
  let res = await get("/user/list/", data);
  return res;
};

export const addNewRole = async ({ payload }) => {
  let res = await postAPI("/role/add", payload);
  return res;
};

export const addNewUser = async ({ payload }) => {
  let res = await postAPI("/user/add", payload);
  return res;
};

export const rolesPrefetchingList = async ({ payload }) => {
  let res = await get("/role/details/" + payload);
  return res;
};

export const usersPrefetchingList = async ({ payload }) => {
  let res = await get("/user/details/" + payload);
  return res;
};

export const roleUpdate = async (id, payload) => {
  let res = await putAPI("/role/update/" + id, payload);
  return res;
};

export const userUpdate = async (id, payload) => {
  let res = await putAPI("/user/update/" + id, payload);
  return res;
};

export const roleMapping = async (id, payload) => {
  let res = await postAPI("/role/" + id + "/manage-module-access/", payload);
  return res;
};

export const userRoleMapping = async (id, payload) => {
  let res = await postAPI("/user/" + id + "/manage-role-access/", payload);
  return res;
};

export const roleDelete = async ({ id }) => {
  return Swal.fire({
    text: "Are you sure you want to delete?",
    confirmButtonText: "Yes",
    showCancelButton: true,
  }).then(async (result) => {
    if (result.value) {
      let res = await deleteAPI("/role/delete/" + id);
      return Swal.fire({
        text: res.message,
        timer: 1500,
      }).then(() => {
        return res;
      });
    }
  });
};

export const userDelete = async ({ id }) => {
  return Swal.fire({
    text: "Are you sure you want to delete?",
    confirmButtonText: "Yes",
    showCancelButton: true,
  }).then(async (result) => {
    if (result.value) {
      let res = await deleteAPI("/user/delete/" + id);
      return Swal.fire({
        text: res.message,
        timer: 1500,
      }).then(() => {
        return res;
      });
    }
  });
};

export const uniqueRole = async ({ ID, name }) => {
  let data = {
    // ID:"",
    name,
  };
  let res = await postAPI("/role/check-uniqueness", data);
  return res;
};

export const uniqueUser = async ({ ID, email, mobile }) => {
  let data = {
    ID: ID,
    email: email,
    phone: mobile,
  };
  let res = await postAPI("/user/check-uniqueness", data);
  return res;
};
